import {
  actionTypes
} from '@src/actions/inputActions';
import { IInComeItem } from '@src/interfaces/IInputState';

export const initialIncomeState: IInComeItem = {
  amount_income: null,
  time_income: '',
  note_income: '',
  isError: {
    amount: false,
    note: false
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function incomeReducer(state = initialIncomeState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_DATA_INCOME_FIELD:
    {
      const { name, value } = action;
      return {
        ...state,
        [name]: value
      };
    }
    case actionTypes.CLEAR_DATA_INCOME:
    {
      const { data } = action;
      return {
        ...state,
        amount_income: data.amount_income,
        note_income: data.note_income,
        time_income: data.time_income,
        category: data.category
      };
    }
    case actionTypes.VALIDATE_INCOME_ERROR:
    {
      const { key, isError } = action;
      return {
        ...state,
        isError: {
          ...state.isError,
          [key]: isError
        }
      };
    }
    case actionTypes.VALIDATE_INCOME_PASS:
      return {
        ...state,
        error: false
      };
    default:
      return state;
  }
}
