export const getHeaders = () => (
  new Promise((resolve, reject) => {
    const token = sessionStorage ? sessionStorage.getItem('token') : '';
    if (token) {
      resolve({
        Authorization: `${token}`
      });
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Token undefined');
    }
  })
);
