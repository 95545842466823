import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { actionTypes, loadAssetListSuccess } from '@src/actions/homeActions';
import { setLoading } from '@src/actions/commonActions';
import errorHandler from '@src/utils/errorHandler';
import { HomeApiServices } from '@src/api/HomeApiServices';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadAssetListSaga(action: any) {
  const { params } = action.data;
  try {
    yield put(setLoading(true));
    const { data }: AxiosResponse = yield call(HomeApiServices.getAssetList, params);
    yield put(loadAssetListSuccess(data));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

function * watchLoadAssetList() {
  yield takeLatest(actionTypes.LOAD_DATA_ASSET_LIST, loadAssetListSaga);
}

export function * homeSaga() {
  yield all([
    watchLoadAssetList()
  ]);
}
