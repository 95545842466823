import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import __filter from 'lodash.filter';
import { actionTypes, getReportDataSuccess, updateReportType } from '@src/actions/reportActions';
import { reportAPIServices } from '@src/api/reportAPIServices';
import errorHandler from '@src/utils/errorHandler';
import { setLoading } from '@src/actions/commonActions';
import { IReportItem, ISettingInfo } from '@src/interfaces/IReportState';
import Router from 'next/router';
import { userInfoSeletor } from '@src/selectors/userSeletor';
import { getAccountType, getAndRemovePrevRoute } from '@src/utils/functionUtils';
import { AccountType } from '@src/utils/appContanst';

function * getReportDataSaga(action: {type: string, reportType: string}) {
  try {
    yield put(setLoading(true));
    const { reportType } = action;
    yield put(updateReportType(reportType));
    const { data } = yield call(reportAPIServices.getReportData, reportType);
    const userInfo = yield select(userInfoSeletor);
    const reportSetting:ISettingInfo = userInfo.report_setting;
    if (!reportSetting.is_included_ec) {
      data.expenses = __filter(data.expenses, (item:IReportItem) => ![AccountType.mf_ec].includes(getAccountType(item.account.data_source)));
    }

    yield put(getReportDataSuccess(data));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}
function * updateReportSettingDataSaga(action: {type: string, settingInfo: ISettingInfo}) {
  try {
    yield put(setLoading(true));
    yield call(reportAPIServices.updateReportSettingData, action.settingInfo);
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

function * handleBackInReportSettingSaga() {
  try {
    yield put(setLoading(true));
    const userInfo = yield select(userInfoSeletor);
    yield call(reportAPIServices.updateReportSettingData, { ...userInfo.report_setting });
    yield put(setLoading(false));
    Router.push(getAndRemovePrevRoute());
  } catch (err) {
    errorHandler(err);
  }
}

function * watchGetReportDataSaga() {
  yield takeLatest(actionTypes.GET_REPORT_DATA, getReportDataSaga);
}

function * watchUpdateReportSettingDataSaga() {
  yield takeLatest(actionTypes.UPDATE_REPORT_SETTING_DATA, updateReportSettingDataSaga);
}

function * watchHandleBackInReportSettingSaga() {
  yield takeLatest(actionTypes.HANDLE_BACK_IN_REPORT_SETTING, handleBackInReportSettingSaga);
}

export function * reportSaga() {
  yield all([
    watchGetReportDataSaga(),
    watchUpdateReportSettingDataSaga(),
    watchHandleBackInReportSettingSaga()
  ]);
}
