import { actionTypes } from '@src/actions/columnActions';
import { IColumnState } from '@src/interfaces/IColumnState';

const initialState: IColumnState = {
  all: {
    isLoadingMore: false,
    isPending: true,
    hasLoadMore: false,
    listColumns: [],
    params: {},
    errors: null
  },
  pickups: {
    isPending: true,
    errors: null,
    listColumns: []
  },
  rankings: {
    isPending: true,
    errors: null,
    listColumns: []
  },
  bookmarks: {
    isLoadingMore: false,
    isPending: true,
    hasLoadMore: false,
    listColumns: [],
    params: {},
    errors: null
  },
  details: {
    isPending: true,
    detail: null
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function columnReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOAD_COLUMN_LIST_SUCCESS: {
      return {
        ...state,
        list: action.items
      };
    }
    // load ranking
    case actionTypes.LOAD_COLUMN_RANKING_PENDING: {
      return {
        ...state,
        rankings: {
          ...state.rankings,
          listColumns: [],
          isPending: true
        }
      };
    }
    case actionTypes.LOAD_COLUMN_RANKING_SUCCESS: {
      return {
        ...state,
        rankings: {
          ...state.rankings,
          isPending: false,
          listColumns: action.data
        }
      };
    }
    case actionTypes.LOAD_COLUMN_RANKING_ERRORED: {
      return {
        ...state,
        rankings: {
          ...state.rankings,
          isPending: false,
          errors: action.data
        }
      };
    }

    // load pickup
    case actionTypes.LOAD_COLUMN_PICKUP_PENDING: {
      return {
        ...state,
        pickups: {
          ...state.pickups,
          listColumns: [],
          isPending: true
        }
      };
    }
    case actionTypes.LOAD_COLUMN_PICKUP_SUCCESS: {
      return {
        ...state,
        pickups: {
          isPending: false,
          errors: null,
          listColumns: action.data
        }
      };
    }
    case actionTypes.LOAD_COLUMN_PICKUP_ERRORED: {
      return {
        ...state,
        pickups: {
          isPending: false,
          errors: action.data,
          listColumns: []
        }
      };
    }
    case actionTypes.LOAD_COLUMN_All_PENDING: {
      return {
        ...state,
        all: {
          ...state.all,
          isPending: true
        }
      };
    }
    case actionTypes.LOAD_COLUMN_ALL_SUCCESS: {
      const { hasLoadMore, listColumns, params } = action.data;
      return {
        ...state,
        all: {
          isPending: false,
          errors: null,
          listColumns,
          hasLoadMore,
          params
        }
      };
    }

    case actionTypes.LOAD_COLUMN_BOOKMARK_PENDING: {
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          isPending: true
        }
      };
    }

    case actionTypes.LOAD_COLUMN_BOOKMARK_SUCCESS: {
      const { hasLoadMore, listColumns, params } = action.data;
      return {
        ...state,
        bookmarks: {
          isPending: false,
          errors: null,
          listColumns,
          hasLoadMore,
          params
        }
      };
    }
    // load more all
    case actionTypes.LOAD_MORE_COLUMN_ALL: {
      const { params } = action.data;
      return {
        ...state,
        all: {
          ...state.all,
          isPending: false,
          params
        }
      };
    }
    case actionTypes.LOAD_MORE_COLUMN_ALL_SUCCESS: {
      const { hasLoadMore, listColumns, params } = action.data;
      return {
        ...state,
        all: {
          ...state.all,
          isLoadingMore: false,
          isPending: false,
          hasLoadMore,
          listColumns,
          params
        }
      };
    }

    case actionTypes.LOAD_MORE_COLUMN_ALL_PENDING: {
      return {
        ...state,
        all: {
          ...state.all,
          isLoadingMore: true
        }
      };
    }
    case actionTypes.LOAD_MORE_COLUMN_ALL_ERRORED: {
      return {
        ...state,
        all: {
          ...state.all,
          isLoadingMore: false,
          hasLoadMore: false
        }
      };
    }
    // load more bookmark
    case actionTypes.LOAD_MORE_COLUMN_BOOKMARK: {
      const { params } = action.data;
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          isPending: false,
          params
        }
      };
    }
    case actionTypes.LOAD_MORE_COLUMN_BOOKMARK_SUCCESS: {
      const { hasLoadMore, listColumns, params } = action.data;
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          isLoadingMore: false,
          isPending: false,
          hasLoadMore,
          listColumns,
          params
        }
      };
    }

    case actionTypes.LOAD_MORE_COLUMN_BOOKMARK_PENDING: {
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          isLoadingMore: true
        }
      };
    }
    case actionTypes.LOAD_MORE_COLUMN_BOOKMARK_ERRORED: {
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          isLoadingMore: false,
          hasLoadMore: false
        }
      };
    }

    case actionTypes.LOAD_COLUMN_DETAILS_PENDING: {
      return {
        ...state,
        details: {
          ...state.details,
          isPending: true
        }
      };
    }
    case actionTypes.LOAD_COLUMN_DETAILS_SUCCESS: {
      return {
        ...state,
        details: {
          isPending: false,
          detail: action.item
        }
      };
    }

    case actionTypes.BOOKMARK_COLUMN_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          detail: {
            ...state.details.detail,
            is_bookmarked: action.data
          }
        }
      };
    }

    case actionTypes.READ_COLUMN_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          detail: {
            ...state.details.detail,
            is_readed: action.data
          }
        }
      };
    }

    default:
      return state;
  }
}
