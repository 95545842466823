import { IInputState } from '@src/interfaces/IInputState';

export const actionTypes = {
  CREATE_INPUT: 'CREATE_INPUT',
  CREATE_INPUT_PENDING: 'CREATE_INPUT_PENDING',
  CREATE_INPUT_SUCCESS: 'CREATE_INPUT_SUCCESS',
  UPDATE_DATA_FIELD: 'UPDATE_DATA_FIELD',
  UPDATE_DATA_INCOME_FIELD: 'UPDATE_DATA_INCOME_FIELD',
  CREATE_INPUT_INCOME: 'CREATE_INPUT_INCOME',
  CREATE_INPUT_INCOME_PENDING: 'CREATE_INPUT_INCOME_PENDING',
  CREATE_INPUT_INCOME_SUCCESS: 'CREATE_INPUT_INCOME_SUCCESS',
  CLEAR_DATA_EXPENSE: 'CLEAR_DATA_EXPENSE',
  CLEAR_DATA_INCOME: 'CLEAR_DATA_INCOME',
  VALIDATE_EXPENSE_ERROR: 'VALIDATE_EXPENSE_ERROR',
  VALIDATE_EXPENSE_PASS: 'VALIDATE_EXPENSE_PASS',
  VALIDATE_INCOME_ERROR: 'VALIDATE_INCOME_ERROR',
  VALIDATE_INCOME_PASS: 'VALIDATE_INCOME_PASS'
};

export function createInput() {
  return {
    type: actionTypes.CREATE_INPUT
  };
}

export function createInputSuccess(input: IInputState) {
  return {
    type: actionTypes.CREATE_INPUT_SUCCESS,
    input
  };
}

export function createInputIncome() {
  return {
    type: actionTypes.CREATE_INPUT_INCOME
  };
}

export function createInputIncomeSuccess(input: IInputState) {
  return {
    type: actionTypes.CREATE_INPUT_INCOME_SUCCESS,
    input
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateDataField(name: string, value: any) {
  return {
    type: actionTypes.UPDATE_DATA_FIELD,
    name,
    value
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateDataIncomeField(name: string, value: any) {
  return {
    type: actionTypes.UPDATE_DATA_INCOME_FIELD,
    name,
    value
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearDataExpense(data: any) {
  return {
    type: actionTypes.CLEAR_DATA_EXPENSE,
    data
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearDataIncome(data: any) {
  return {
    type: actionTypes.CLEAR_DATA_INCOME,
    data
  };
}

export function validateInputError(key: string, isError: boolean) {
  return {
    type: actionTypes.VALIDATE_EXPENSE_ERROR,
    key,
    isError
  };
}

export function validateInputPass() {
  return {
    type: actionTypes.VALIDATE_EXPENSE_PASS
  };
}

export function validateIncomeError(key: string, isError: boolean) {
  return {
    type: actionTypes.VALIDATE_INCOME_ERROR,
    key,
    isError
  };
}

export function validateIncomePass() {
  return {
    type: actionTypes.VALIDATE_INCOME_PASS
  };
}
