import _orderBy from 'lodash.orderby';
import _groupBy from 'lodash.groupby';
import _filter from 'lodash.filter';
import { IExpenseHistoryItem, IReportItem, IReportState } from '@src/interfaces/IReportState';
import { actionTypes } from '@src/actions/reportActions';
import { AccountType } from '@src/utils/appContanst';
import { getAccountType } from '@src/utils/functionUtils';
import { getTimeFromTimeServer } from '@src/utils/format';
import { initialTotalCategory } from '@src/utils/initialData';

const initialState: IReportState = {
  incomes: [],
  incomesTotal: { ...initialTotalCategory },
  expenses: [],
  expensesTotal: { ...initialTotalCategory },
  expense_history: [],
  expenseByTime: {},
  reportType: 'week',
  totalBalance: 0
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reportReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_REPORT_DATA: {
      return {
        ...state,
        incomesTotal: { ...initialTotalCategory },
        expensesTotal: { ...initialTotalCategory }
      };
    }
    case actionTypes.GET_REPORT_DATA_SUCCESS: {
      const { incomes, expenses, expense_history } = action.data;
      const expensesWithoutUnusedAcc = removeUnusedAccountsExpenses(expenses);
      const incomesTotal = excuteTotalByCategory(incomes);
      const expensesTotal = excuteTotalByCategory(expensesWithoutUnusedAcc);
      const expenseByTime = getExpenseByTime(expense_history);

      return {
        ...state,
        incomes,
        incomesTotal,
        expenses,
        expensesTotal,
        expense_history,
        expenseByTime
      };
    }
    case actionTypes.UPDATE_REPORT_TYPE: {
      return {
        ...state,
        reportType: action.reportType
      };
    }
    default:
      return state;
  }
}

function removeUnusedAccountsExpenses(expenses: Array<IReportItem>) {
  // this case remove expenses of Kantan
  return _filter(expenses, (item: IReportItem) => {
    const { data_source } = item.account;
    return AccountType.au_kantan_kessai !== getAccountType(data_source);
  });
}

function excuteTotalByCategory(services: Array<IReportItem>) {
  let total = 0;
  let pointTotal = 0;
  let bankTotal = 0;
  let creditTotal = 0;
  let emoneyTotal = 0;
  let ecTotal = 0;

  services.forEach((item: IReportItem) => {
    const accountType = getAccountType(item.account.data_source);

    // ponta, au-ponta-market
    if ([AccountType.au_ponta, AccountType.au_ponta_market].includes(accountType)) {
      pointTotal += item.value;
    } else if ([AccountType.au_jbank].includes(accountType)) {
      bankTotal += item.value;
    } else if ([AccountType.au_credit].includes(accountType)) {
      creditTotal += item.value;
    } else if ([AccountType.au_prepaid, AccountType.emoney].includes(accountType)) {
      emoneyTotal += item.value;
    } else if ([AccountType.au_market].includes(accountType)) {
      ecTotal += item.value;
    }
  });

  total = pointTotal + bankTotal + creditTotal + emoneyTotal + ecTotal;
  return { total, pointTotal, bankTotal, creditTotal, emoneyTotal, ecTotal };
}

function getExpenseByTime(expenseHistory: Array<IExpenseHistoryItem>) {
  let newExpenseHistory = _orderBy(expenseHistory, ['setting_date'], 'asc');
  const orderByValues = _orderBy(expenseHistory, ['value'], 'asc');
  let maxValue = orderByValues.length ? orderByValues[0].value : 1;

  // incase maxValue === 0
  maxValue = maxValue || 1;
  newExpenseHistory.forEach((ele:IExpenseHistoryItem) => {
    const parseTime = getTimeFromTimeServer(ele.setting_date);

    ele.percent = Math.round(Math.abs((ele.value / maxValue)) * 100);
    ele.date = `${parseTime.year}-${parseTime.month}`;
  });

  newExpenseHistory = _groupBy(newExpenseHistory, (item: IExpenseHistoryItem) => item.date);

  return newExpenseHistory;
}
