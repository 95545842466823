import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { actionTypes, createInputSuccess, createInputIncomeSuccess, clearDataExpense, clearDataIncome } from '@src/actions/inputActions';
import { setLoading } from '@src/actions/commonActions';
import errorHandler from '@src/utils/errorHandler';
import { InputApiServices } from '@src/api/InputApiServices';
import { dataFieldExpenseSeletor, dataFieldIncomeSeletor } from '@src/selectors/inputSelector';
import { initialExpenseState } from '@src/reducers/expenseReducer';
import { initialIncomeState } from '@src/reducers/incomeReducer';
import { show } from 'redux-modal';

function * createInputSaga() {
  try {
    yield put(setLoading(true));
    const dataInput = yield select(dataFieldExpenseSeletor);

    const params = {
      amount: -Math.abs(dataInput.amount ? dataInput.amount : 0),
      time: dataInput.time,
      note: dataInput.note,
      is_income: false
    };
    const { data } = yield call(InputApiServices.createInput, params);
    yield put(createInputSuccess(data));
    yield put(setLoading(false));
    yield put(show('SUCCESS_MODAL'));
    yield put(clearDataExpense(initialExpenseState));
  } catch (err) {
    errorHandler(err);
  }
}

function * createInputIncomeSaga() {
  try {
    yield put(setLoading(true));
    const dataInput = yield select(dataFieldIncomeSeletor);

    const params = {
      amount: Math.abs(dataInput.amount_income ? dataInput.amount_income : 0),
      time: dataInput.time_income,
      note: dataInput.note_income,
      is_income: true
    };
    const { data } = yield call(InputApiServices.createInput, params);
    yield put(createInputIncomeSuccess(data));
    yield put(setLoading(false));
    yield put(show('SUCCESS_MODAL'));
    yield put(clearDataIncome(initialIncomeState));
  } catch (err) {
    errorHandler(err);
  }
}

function * watchCreateInputSaga() {
  yield takeLatest(actionTypes.CREATE_INPUT, createInputSaga);
}

function * watchCreateInputIncomeSaga() {
  yield takeLatest(actionTypes.CREATE_INPUT_INCOME, createInputIncomeSaga);
}

export function * inputSaga() {
  yield all([
    watchCreateInputSaga(),
    watchCreateInputIncomeSaga()
  ]);
}
