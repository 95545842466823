import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { ColumnsApiServices } from '@src/api/ColumnsApiServices';
import {
  actionTypes,
  loadColumnDetailsSuccess,
  loadColumnRankingSuccess,
  loadColumnPickupPending,
  loadColumnPickupSuccess,
  loadColumnDetailsPending,
  loadColumnRankingPending,
  bookmarkColumnSuccess,
  readColumnSuccess,
  loadColumnAllSuccess,
  loadColumnBookmarkSuccess,
  loadColumnAllPending,
  loadColumnBookmarkPending,
  loadMoreColumnAllSuccess,
  loadMoreColumnAllPending,
  loadMoreColumnBookmarkSuccess,
  loadMoreColumnBookmarkPending
} from '@src/actions/columnActions';
import { columnAllSeletor, columnBookmarkSeletor, columnDetailColumnSeletor } from '@src/selectors/columnSeletor';
import { setLoading } from '@src/actions/commonActions';
import errorHandler from '@src/utils/errorHandler';
import { ColumnsPageView } from '@src/analyticsEvents/columnsAnalyticsEvents';

function * loadColumnAllSaga() {
  try {
    yield put(loadColumnAllPending());
    const { data: { articles, paging_key } }: AxiosResponse = yield call(ColumnsApiServices.getColumnsAll);
    yield put(
      loadColumnAllSuccess({ hasLoadMore: (paging_key !== '' && paging_key !== null), listColumns: articles || [], params: paging_key })
    );
  } catch (err) {
    yield put(
      loadColumnAllSuccess({ hasLoadMore: false, listColumns: [] })
    );
    errorHandler(err);
  }
}

function * loadColumnBookmarkSaga() {
  try {
    yield put(loadColumnBookmarkPending());
    const { data }: AxiosResponse = yield call(ColumnsApiServices.getColumnsBookmarks);
    const { articles, paging_key } = data || {};

    yield put(
      loadColumnBookmarkSuccess({ hasLoadMore: (paging_key !== '' && paging_key !== null), listColumns: articles || [], params: paging_key })
    );
  } catch (err) {
    yield put(
      loadColumnBookmarkSuccess({ hasLoadMore: false, listColumns: [] })
    );
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadMoreColumnAllSaga(action: any) {
  try {
    const { params } = action.data;
    const { listColumns } = yield select(columnAllSeletor);
    yield put(loadMoreColumnAllPending());
    const { data: { articles, paging_key } }: AxiosResponse = yield call(ColumnsApiServices.getColumnsAll, params);
    yield put(
      loadMoreColumnAllSuccess({
        hasLoadMore: (paging_key !== '' && paging_key !== null),
        listColumns: articles !== null ? listColumns.concat(articles) : listColumns,
        params: paging_key
      })
    );
  } catch (err) {
    yield put(
      loadMoreColumnAllSuccess({ hasLoadMore: false, listColumns: [] })
    );
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadMoreColumnBookmarkSaga(action: any) {
  try {
    const { params } = action.data;
    const { listColumns } = yield select(columnBookmarkSeletor);
    yield put(loadMoreColumnBookmarkPending());
    const { data }: AxiosResponse = yield call(ColumnsApiServices.getColumnsBookmarks, params);
    const { articles, paging_key } = data || { articles: null, paging_key: '' };
    yield put(
      loadMoreColumnBookmarkSuccess({
        hasLoadMore: (paging_key !== '' && paging_key !== null),
        listColumns: articles !== null ? listColumns.concat(articles) : listColumns,
        params: paging_key
      })
    );
  } catch (err) {
    yield put(
      loadMoreColumnBookmarkSuccess({ hasLoadMore: false, listColumns: [] })
    );
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadColumnRankingSaga(action: any) {
  try {
    yield put(loadColumnRankingPending());
    const { data: { articles } }: AxiosResponse = yield call(ColumnsApiServices.getColumnsRanking, action.limit);
    yield put(loadColumnRankingSuccess(articles || []));
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadColumnPickupSaga(action: any) {
  try {
    yield put(loadColumnPickupPending());
    const { data: { articles } }: AxiosResponse = yield call(ColumnsApiServices.getColumnsPickup, action.limit);
    yield put(loadColumnPickupSuccess(articles || []));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadColumnDetailsSaga(action: any) {
  try {
    yield put(loadColumnDetailsPending());
    const { data }: AxiosResponse = yield call(ColumnsApiServices.getColumnDetail, action.id);
    ColumnsPageView.logPageViewColumnsDetail(data.title);
    yield put(loadColumnDetailsSuccess(data));
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * bookmarkColumnSaga(action: any) {
  try {
    const column_id = action.data;
    const { is_bookmarked } = yield select(columnDetailColumnSeletor);
    yield put(setLoading(true));
    const { data, status }: AxiosResponse = yield call(
      is_bookmarked ? ColumnsApiServices.deleteBookmarkColumn : ColumnsApiServices.bookmarkColumn, column_id
    );
    if (status >= 200 && status < 300) {
      yield put(setLoading(false));
      yield put(bookmarkColumnSuccess(!is_bookmarked));
      return;
    }
    throw data;
  } catch (err) {
    yield put(setLoading(false));
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * readColumnSaga(action: any) {
  try {
    const column_id = action.data;
    const { data, status }: AxiosResponse = yield call(ColumnsApiServices.readColumn, column_id);
    if (status >= 200 && status < 300) {
      yield put(readColumnSuccess(true));
      return;
    }
    throw data;
  } catch (err) {
    yield put(setLoading(false));
    errorHandler(err);
  }
}

function * watchLoadColumnAll() {
  yield takeLatest(actionTypes.LOAD_COLUMN_ALL, loadColumnAllSaga);
}

function * watchLoadColumnBookMark() {
  yield takeLatest(actionTypes.LOAD_COLUMN_ALL, loadColumnBookmarkSaga);
}

function * watchLoadMoreColumnAll() {
  yield takeLatest(actionTypes.LOAD_MORE_COLUMN_ALL, loadMoreColumnAllSaga);
}

function * watchLoadMoreColumnBookmark() {
  yield takeLatest(actionTypes.LOAD_MORE_COLUMN_BOOKMARK, loadMoreColumnBookmarkSaga);
}

function * watchLoadColumnRanking() {
  yield takeLatest(actionTypes.LOAD_COLUMN_RANKING, loadColumnRankingSaga);
}

function * watchLoadColumnPickup() {
  yield takeLatest(actionTypes.LOAD_COLUMN_PICKUP, loadColumnPickupSaga);
}

function * watchLoadColumnDetails() {
  yield takeLatest(actionTypes.LOAD_COLUMN_DETAILS, loadColumnDetailsSaga);
}

function * watchBookmarkColumn() {
  yield takeLatest(actionTypes.BOOKMARK_COLUMN, bookmarkColumnSaga);
}

function * watchReadColumn() {
  yield takeLatest(actionTypes.READ_COLUMN, readColumnSaga);
}

export function * columnSaga() {
  yield all([
    watchLoadColumnAll(),
    watchLoadColumnBookMark(),
    watchLoadMoreColumnAll(),
    watchLoadMoreColumnBookmark(),
    watchLoadColumnDetails(),
    watchLoadColumnRanking(),
    watchLoadColumnPickup(),
    watchBookmarkColumn(),
    watchReadColumn()
  ]);
}
