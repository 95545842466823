import { actionTypes } from '@src/actions/historyActions';
import { IHistoryState } from '@src/interfaces/IHistoryState';
import { AnyAction } from 'redux';
import { ICategorySummary } from '@src/interfaces/ICategoryItem';
import { ITransactionItem } from '@src/interfaces/ITransactionState';
import _filter from 'lodash.filter';
import _clone from 'lodash.clone';
import _findIndex from 'lodash/findIndex';
import { sumIncomeExpense } from '@src/utils/functionUtils';

export const initialHistoryState: IHistoryState = {
  transactions: [],
  filterTransactions: [],
  isFilterIncomeTran: true,
  isFilterExpenseTran: true,
  isLoadingTransactions: true,
  products: {},
  stocks: [],
  params: {
    time: '',
    account: {
      id: '',
      name: ''
    }
  },
  categories: [],
  totalCategory: {
    id: '',
    name: 'すべての支出',
    sum: 0,
    transactions_count: 0,
    is_active: true,
    icon: ''
  }
};

export function historyReducer(state = initialHistoryState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_HISTORIES: {
      return {
        ...state,
        isLoadingTransactions: true
      };
    }
    case actionTypes.LOAD_HISTORY_SUCCESS: {
      const { data } = action;
      const { isFilterExpenseTran, isFilterIncomeTran } = state;
      const firstTransaction = data.transactions[0];
      const total = sumIncomeExpense(data.transactions).expense;

      return {
        ...state,
        ...data,
        isLoadingTransactions: false,
        filterTransactions: handleFilterTransactions(data.transactions, isFilterIncomeTran, isFilterExpenseTran),
        totalCategory: {
          id: firstTransaction ? firstTransaction.category.id : '',
          name: firstTransaction ? firstTransaction.category.name : '',
          icon: firstTransaction ? firstTransaction.category.icon : '',
          sum: total
        }
      };
    }
    case actionTypes.LOAD_STOCK_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.products,
        stocks: action.stocks
      };
    }
    case actionTypes.UPDATE_PARAMS: {
      const { name, value } = action;
      return {
        ...state,
        params: {
          ...state.params,
          [name]: value
        }
      };
    }
    case actionTypes.RESET_TIME_PARAM: {
      return {
        ...state,
        params: {
          ...state.params,
          time: ''
        },
        isFilterIncomeTran: true,
        isFilterExpenseTran: true
      };
    }
    case actionTypes.FILTER_TRANSACTIONS: {
      const { transactionType } = action;
      const { isFilterExpenseTran, isFilterIncomeTran, transactions } = state;
      const newFilterIncomeTran = transactionType === 'INCOME' ? !isFilterIncomeTran : isFilterIncomeTran;
      const newFilterExpenseTran = transactionType === 'EXPENSE' ? !isFilterExpenseTran : isFilterExpenseTran;
      const filterTransactions = handleFilterTransactions(transactions, newFilterIncomeTran, newFilterExpenseTran);

      return {
        ...state,
        filterTransactions: filterTransactions,
        isFilterIncomeTran: newFilterIncomeTran,
        isFilterExpenseTran: newFilterExpenseTran
      };
    }
    case actionTypes.UPDATE_TRANSACTION_LIST: {
      const { transaction } = action;
      const { filterTransactions, transactions } = state;
      const newTransactions = updateTransaction(transactions, transaction);
      const newFilterTransactions = updateTransaction(filterTransactions, transaction);
      return {
        ...state,
        transactions: newTransactions,
        filterTransactions: newFilterTransactions
      };
    }
    case actionTypes.REMOVE_ITEM_IN_TRANSACTION_LIST: {
      const { transaction } = action;
      const { filterTransactions, transactions } = state;
      const filterCondition = (ele: ITransactionItem) => (ele.id != transaction.id);

      return {
        ...state,
        transactions: _filter(transactions, filterCondition),
        filterTransactions: _filter(filterTransactions, filterCondition)
      };
    }
    default:
      return state;
  }
}

function updateTransaction(transactions: Array<ITransactionItem>, transaction: ITransactionItem) {
  const transactionsCloner = _clone(transactions);
  const updateIndex = _findIndex(transactionsCloner, ['id', transaction.id]);
  transactionsCloner[updateIndex] = transaction;
  return transactionsCloner;
}

function handleFilterTransactions(transactions: Array<ITransactionItem>, isFilterIncomeTran: boolean, isFilterExpenseTran: boolean) {
  const incomeTrans = isFilterIncomeTran ? _filter(transactions, (item: ITransactionItem) => item.is_income) : [];
  const expenseTrans = isFilterExpenseTran ? _filter(transactions, (item: ITransactionItem) => !item.is_income) : [];

  return incomeTrans.concat(expenseTrans);
}
