import { all, put, takeLatest } from 'redux-saga/effects';
import { actionTypes, setLoading, updateEditingStatus } from '@src/actions/commonActions';
import { getAndRemovePrevRoute } from '@src/utils/functionUtils';
import Router from 'next/router';

export function * handleEnableLoadingModal(useLoadingModal: boolean) {
  if (useLoadingModal) {
    yield put(setLoading(true));
  };
}

export function * handleDisableLoadingModal(useLoadingModal: boolean) {
  if (useLoadingModal) {
    yield put(setLoading(false));
  };
}

export function * handleCancelEditingStatusSaga() {
  yield put(updateEditingStatus(false));
  yield Router.push(getAndRemovePrevRoute());
}

function * watchHandleCancelEditingStatus() {
  yield takeLatest(actionTypes.HANDLE_CANCEL_EDITING_STATUS, handleCancelEditingStatusSaga);
}

export function * commonSaga() {
  yield all([
    watchHandleCancelEditingStatus()
  ]);
}
