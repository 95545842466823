import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';
import { columnReducer } from './columnReducer';
import { userReducer } from './userReducer';
import { commonReducer } from './commonReducer';
import { lifeplanReducer } from './lifeplanReducer';
import { lifeStyleReducer } from './lifeStyleReducer';
import { bookingReducer } from './bookingReducer';
import { balanceReducer } from './balanceReducer';
import { settingReducer } from './settingReducer';
import { tutorialReducer } from './tutorialReducer';
import { homeReducer } from './homeReducer';
import { expenseReducer } from './expenseReducer';
import { categoryReducer } from './categoryReducer';
import { accountReducer } from './accountReducer';
import { incomeReducer } from './incomeReducer';
import { transactionReducer } from './transactionReducer';
import { historyReducer } from './historyReducer';
import { reportReducer } from './reportReducer';
import { bannerReducer } from './bannerReducer';
import { loginLinkReducer } from './loginLinkReducer';

export const appReducer = combineReducers({
  columnState: columnReducer,
  userState: userReducer,
  commonState: commonReducer,
  lifeplanState: lifeplanReducer,
  lifeStyleState: lifeStyleReducer,
  bookingState: bookingReducer,
  balanceState: balanceReducer,
  settingState: settingReducer,
  tutorialState: tutorialReducer,
  homeState: homeReducer,
  expenseState: expenseReducer,
  categoryState: categoryReducer,
  accountState: accountReducer,
  modal,
  incomeState: incomeReducer,
  transactionState: transactionReducer,
  historyState: historyReducer,
  reportState: reportReducer,
  bannerState: bannerReducer,
  loginLinkState: loginLinkReducer
});
