import { all } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import 'isomorphic-unfetch';
import { userSaga } from './userSaga';
import { columnSaga } from './columnSaga';
import { lifeplanSaga } from './lifeplanSaga';
import { homeSaga } from './homeSaga';
import { balanceSaga } from './balanceSaga';
import { inputSaga } from './inputSaga';
import { categorySaga } from './categorySaga';
import { accountSaga } from './accountSaga';
import { transactionSaga } from './transactionSaga';
import { historySaga } from './historySaga';
import { commonSaga } from './commonSaga';
import { reportSaga } from './reportSaga';
import { bannerSaga } from './bannerSaga';
import { loginLinkSaga } from './loginLinkSaga';

es6promise.polyfill();

export function * appSaga() {
  yield all([
    userSaga(),
    columnSaga(),
    lifeplanSaga(),
    homeSaga(),
    balanceSaga(),
    inputSaga(),
    categorySaga(),
    accountSaga(),
    transactionSaga(),
    historySaga(),
    commonSaga(),
    reportSaga(),
    bannerSaga(),
    loginLinkSaga()
  ]);
}
