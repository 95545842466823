import { IColumn } from '@src/interfaces/IColumnState';

export const actionTypes = {
  LOAD_COLUMN_LIST: 'LOAD_COLUMN_LIST',
  LOAD_COLUMN_LIST_SUCCESS: 'LOAD_COLUMN_LIST_SUCCESS',

  LOAD_COLUMN_PICKUP: 'LOAD_COLUMN_PICKUP',
  LOAD_COLUMN_PICKUP_PENDING: 'LOAD_COLUMN_PICKUP_PENDING',
  LOAD_COLUMN_PICKUP_SUCCESS: 'LOAD_COLUMN_PICKUP_SUCCESS',
  LOAD_COLUMN_PICKUP_ERRORED: 'LOAD_COLUMN_PICKUP_ERRORED',

  LOAD_COLUMN_RANKING: 'LOAD_COLUMN_RANKING',
  LOAD_COLUMN_RANKING_PENDING: 'LOAD_COLUMN_RANKING_PENDING',
  LOAD_COLUMN_RANKING_SUCCESS: 'LOAD_COLUMN_RANKING_SUCCESS',
  LOAD_COLUMN_RANKING_ERRORED: 'LOAD_COLUMN_RANKING_ERRORED',

  LOAD_COLUMN_BY_TYPE: 'LOAD_COLUMN_BY_TYPE',
  LOAD_COLUMN_BY_TYPE_PENDING: 'LOAD_COLUMN_BY_TYPE_PENDING',
  LOAD_COLUMN_BY_TYPE_SUCCESS: 'LOAD_COLUMN_BY_TYPE_SUCCESS',
  LOAD_COLUMN_BY_TYPE_ERRORED: 'LOAD_COLUMN_BY_TYPE_ERRORED',
  LOAD_MORE_COLUMN_BY_TYPE: 'LOAD_MORE_COLUMN_BY_TYPE',
  LOAD_MORE_COLUMN_BY_TYPE_PENDING: 'LOAD_MORE_COLUMN_BY_TYPE_PENDING',
  LOAD_MORE_COLUMN_BY_TYPE_SUCCESS: 'LOAD_MORE_COLUMN_BY_TYPE_SUCCESS',
  LOAD_MORE_COLUMN_BY_TYPE_ERRORED: 'LOAD_MORE_COLUMN_BY_TYPE_ERRORED',

  LOAD_COLUMN_ALL: 'LOAD_COLUMN_ALL',
  LOAD_COLUMN_All_PENDING: 'LOAD_COLUMN_All_PENDING',
  LOAD_COLUMN_ALL_SUCCESS: 'LOAD_COLUMN_ALL_SUCCESS',
  LOAD_COLUMN_BOOKMARK: 'LOAD_COLUMN_BOOKMARK',
  LOAD_COLUMN_BOOKMARK_PENDING: 'LOAD_COLUMN_BOOKMARK_PENDING',
  LOAD_COLUMN_BOOKMARK_SUCCESS: 'LOAD_COLUMN_BOOKMARK_SUCCESS',

  LOAD_MORE_COLUMN_ALL: 'LOAD_MORE_COLUMN_ALL',
  LOAD_MORE_COLUMN_ALL_PENDING: 'LOAD_MORE_COLUMN_ALL_PENDING',
  LOAD_MORE_COLUMN_ALL_SUCCESS: 'LOAD_MORE_COLUMN_ALL_SUCCESS',
  LOAD_MORE_COLUMN_ALL_ERRORED: 'LOAD_MORE_COLUMN_ALL_ERRORED',

  LOAD_MORE_COLUMN_BOOKMARK: 'LOAD_MORE_COLUMN_BOOKMARK',
  LOAD_MORE_COLUMN_BOOKMARK_PENDING: 'LOAD_MORE_COLUMN_BOOKMARK_PENDING',
  LOAD_MORE_COLUMN_BOOKMARK_SUCCESS: 'LOAD_MORE_COLUMN_BOOKMARK_SUCCESS',
  LOAD_MORE_COLUMN_BOOKMARK_ERRORED: 'LOAD_MORE_COLUMN_BOOKMARK_ERRORED',

  LOAD_COLUMN_DETAILS: 'LOAD_COLUMN_DETAILS',
  LOAD_COLUMN_DETAILS_PENDING: 'LOAD_COLUMN_DETAILS_PENDING',
  LOAD_COLUMN_DETAILS_SUCCESS: 'LOAD_COLUMN_DETAILS_SUCCESS',

  BOOKMARK_COLUMN: 'BOOKMARK_COLUMN',
  BOOKMARK_COLUMN_PENDING: 'BOOKMARK_COLUMN_PENDING',
  BOOKMARK_COLUMN_SUCCESS: 'BOOKMARK_COLUMN_SUCCESS',
  BOOKMARK_COLUMN_ERRORED: 'BOOKMARK_COLUMN_ERRORED',

  READ_COLUMN: 'READ_COLUMN',
  READ_COLUMN_SUCCESS: 'READ_COLUMN_SUCCESS',
  READ_COLUMN_ERRORED: 'READ_COLUMN_ERRORED'
};

export function loadColumnList() {
  return { type: actionTypes.LOAD_COLUMN_LIST };
}

export function loadColumnListSuccess(items: Array<IColumn>) {
  return {
    type: actionTypes.LOAD_COLUMN_LIST_SUCCESS,
    items
  };
}

// ranking
export function loadColumnRanking(limit: number) {
  return {
    type: actionTypes.LOAD_COLUMN_RANKING,
    limit: limit
  };
}

export function loadColumnRankingPending() {
  return {
    type: actionTypes.LOAD_COLUMN_RANKING_PENDING
  };
}

export function loadColumnRankingSuccess(aticles: Array<IColumn>) {
  return {
    type: actionTypes.LOAD_COLUMN_RANKING_SUCCESS,
    data: aticles
  };
}
export function loadColumnRankingErrored() {
  return {
    type: actionTypes.LOAD_COLUMN_RANKING_ERRORED
  };
}

// pickup
export function loadColumnPickup(limit: number) {
  return {
    type: actionTypes.LOAD_COLUMN_PICKUP,
    limit: limit
  };
}

export function loadColumnPickupPending() {
  return {
    type: actionTypes.LOAD_COLUMN_PICKUP_PENDING
  };
}
export function loadColumnPickupSuccess(items: Array<IColumn>) {
  return {
    type: actionTypes.LOAD_COLUMN_PICKUP_SUCCESS,
    data: items
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnPickupErrored(errors: any) {
  return {
    type: actionTypes.LOAD_COLUMN_PICKUP_ERRORED,
    data: errors
  };
}
// others type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnAll(params?: any) {
  return {
    type: actionTypes.LOAD_COLUMN_ALL,
    data: {
      params
    }
  };
}
export function loadColumnAllPending() {
  return {
    type: actionTypes.LOAD_COLUMN_All_PENDING
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnAllSuccess({ hasLoadMore, listColumns, params }: any) {
  return {
    type: actionTypes.LOAD_COLUMN_ALL_SUCCESS,
    data: { hasLoadMore, listColumns, params }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnBookmark(params?: any) {
  return {
    type: actionTypes.LOAD_COLUMN_BOOKMARK,
    data: {
      params
    }
  };
}
export function loadColumnBookmarkPending() {
  return {
    type: actionTypes.LOAD_COLUMN_BOOKMARK_PENDING
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnBookmarkSuccess({ hasLoadMore, listColumns, params, type }: any) {
  return {
    type: actionTypes.LOAD_COLUMN_BOOKMARK_SUCCESS,
    data: { hasLoadMore, listColumns, params, type }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnByType(others_type: 'bookmark' | 'all', params: any) {
  return {
    type: actionTypes.LOAD_COLUMN_BY_TYPE,
    data: {
      others_type,
      params
    }
  };
}

export function loadColumnByTypePending() {
  return {
    type: actionTypes.LOAD_COLUMN_BY_TYPE_PENDING
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnByTypeSuccess({ hasLoadMore, listColumns, params }: any) {
  return {
    type: actionTypes.LOAD_COLUMN_BY_TYPE_SUCCESS,
    data: { hasLoadMore, listColumns, params }
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadColumnByTypeErrored(errors: any) {
  return {
    type: actionTypes.LOAD_COLUMN_BY_TYPE_ERRORED,
    data: errors
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadMoreColumnByType(params: any) {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BY_TYPE,
    data: {
      params
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadMoreColumnAll(params: any) {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_ALL,
    data: {
      params
    }
  };
}

export function loadMoreColumnAllPending() {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_ALL_PENDING
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadMoreColumnAllSuccess({ hasLoadMore, listColumns, params }: any) {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_ALL_SUCCESS,
    data: { hasLoadMore, listColumns, params }
  };
}
export function loadMoreColumnAllErrored() {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_ALL_ERRORED
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadMoreColumnBookmark(params: any) {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BOOKMARK,
    data: {
      params
    }
  };
}

export function loadMoreColumnBookmarkPending() {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BOOKMARK_PENDING
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadMoreColumnBookmarkSuccess({ hasLoadMore, listColumns, params }: any) {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BOOKMARK_SUCCESS,
    data: { hasLoadMore, listColumns, params }
  };
}
export function loadMoreColumnBookmarkErrored() {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BOOKMARK_ERRORED
  };
}

export function loadMoreColumnByTypePending() {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BY_TYPE_PENDING
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadMoreColumnByTypeSuccess({ hasLoadMore, listColumns, params }: any) {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BY_TYPE_SUCCESS,
    data: { hasLoadMore, listColumns, params }
  };
}
export function loadMoreColumnByTypeErrored() {
  return {
    type: actionTypes.LOAD_MORE_COLUMN_BY_TYPE_ERRORED
  };
}

// detail

export function loadColumnDetails(id: string) {
  return {
    type: actionTypes.LOAD_COLUMN_DETAILS,
    id
  };
}

export function loadColumnDetailsPending() {
  return {
    type: actionTypes.LOAD_COLUMN_DETAILS_PENDING
  };
}
export function loadColumnDetailsSuccess(item: IColumn) {
  return {
    type: actionTypes.LOAD_COLUMN_DETAILS_SUCCESS,
    item
  };
}

// bookmark

export function bookmarkColumnPending() {
  return {
    type: actionTypes.BOOKMARK_COLUMN_PENDING
  };
}
export function bookmarkColumn(column_id: string) {
  return {
    type: actionTypes.BOOKMARK_COLUMN,
    data: column_id
  };
}
export function bookmarkColumnSuccess(is_bookmarked: boolean) {
  return {
    type: actionTypes.BOOKMARK_COLUMN_SUCCESS,
    data: is_bookmarked
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function bookmarkColumnErrored(error: any) {
  return {
    type: actionTypes.BOOKMARK_COLUMN_ERRORED,
    data: error
  };
}
export function readColumn(column_id: string) {
  return {
    type: actionTypes.READ_COLUMN,
    data: column_id
  };
}
export function readColumnSuccess(is_readed: boolean) {
  return {
    type: actionTypes.READ_COLUMN_SUCCESS,
    data: is_readed
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function readColumnErrored(error: any) {
  return {
    type: actionTypes.READ_COLUMN_ERRORED,
    data: error
  };
}
