import { assetTopAnalyticsEvents } from '@src/analyticsEvents/assetTopAnalyticsEvents';
import { reportAnalyticsEvents } from '@src/analyticsEvents/reportAnalyticsEvent';
import { IBalanceState, IUserCondition } from '@src/interfaces/IBalanceState';
import { IBundleResponse } from '@src/interfaces/IBundle';
import { AccountType, TRACKING_ACC, TRACKING_STATUS, TRACKING_TEXT } from '@src/utils/appContanst';
import { getAccountType, getBundleMsg } from '@src/utils/functionUtils';
import { initialUserCondition } from '@src/utils/initialData';

export const handleTrackingAccountGA = (
  accountName: string,
  dataSource: number,
  isReportPage: boolean | undefined
) => {
  let trackingName = '';

  switch (accountName) {
    case 'Ponta ポイント (au限定)': {
      trackingName = 'Ponta ポイント';
      break;
    }
    case 'auかんたん決済' : {
      trackingName = 'auかんたん決済(金額タップ)';
      break;
    }
    default:
      trackingName = accountName;
  }

  handleTrackingGAEvent(trackingName, isReportPage, dataSource);
};

export const trackingAAServiceAssetTop = (trackingAction: string) => {
  if (TRACKING_ACC.includes(trackingAction) ||
      TRACKING_STATUS.includes(trackingAction) ||
      TRACKING_TEXT.includes(trackingAction)
  ) {
    return assetTopAnalyticsEvents.eventClickAAService(trackingAction);
  }
};

export const sendTrackingAuAnalyticsEventInReport = (dataSource: number) => {
  const accType = getAccountType(dataSource);

  switch (accType) {
    case AccountType.au_stock: {
      reportAnalyticsEvents.eventClickAuStockReport();
      break;
    }
    case AccountType.au_jbank: {
      reportAnalyticsEvents.eventClickAuJbankReport();
      break;
    }
    case AccountType.au_prepaid: {
      reportAnalyticsEvents.eventClickAuPayReport();
      break;
    }
  }
};

export const handleTrackingGAEvent = (
  trackingAction: string,
  isReportPage: boolean | undefined,
  dataSource: number
) => {
  if (isReportPage) {
    sendTrackingAuAnalyticsEventInReport(dataSource);
  } else {
    trackingAAServiceAssetTop(trackingAction);
  }
};

export const handleTrackingCampaignMsgGAForBundle = (
  balanceState: IBalanceState,
  auAccShowcampaignText: string,
  setUserCondition?: IUserCondition,
  bundleSettings?: IBundleResponse
) => {
  const { bank, credit, e_money, stock } = balanceState;
  const allAccount = [
    ...bank.accounts,
    ...credit.accounts,
    ...e_money.accounts,
    ...stock.accounts
  ];

  allAccount.forEach((account) => {
    const accType = getAccountType(account.data_source);
    const isShowCampaign = [accType, 'ALL'].includes(auAccShowcampaignText);
    const isAuPay = AccountType.au_prepaid === accType;
    const accountStatus = !account.id ? 'unlinked' : '';
    const bundleMsg =
      bundleSettings &&
      getBundleMsg(
        setUserCondition || initialUserCondition,
        bundleSettings,
        account.data_source,
        accountStatus
      );
    if (bundleMsg && bundleMsg.campaignMsg) {
      if (auAccShowcampaignText && isShowCampaign && !isAuPay) {
        assetTopAnalyticsEvents.eventDisplayBundle(
          '【visible】トップ',
          bundleMsg.action || ''
        );
      }
      if (isAuPay) {
        assetTopAnalyticsEvents.eventDisplayBundle(
          '【visible】トップ',
          bundleMsg.action || ''
        );
      }
    }
    if (bundleMsg && bundleMsg.bundleStatus) {
      assetTopAnalyticsEvents.eventDisplayBundle(
        '【visible】トップ',
        bundleMsg.action || ''
      );
    }
  });
};
