import {
  actionTypes
} from '@src/actions/categoryActions';
import { ICategoryState } from '@src/interfaces/ICategoryState';

const initialState: ICategoryState = {
  expense: [{
    id: '',
    icon: '',
    name: ''
  }],
  income: [{
    id: '',
    icon: '',
    name: ''
  }],
  filterSelect: {
    id: '1',
    label: '履歴が新しい順',
    value: ''
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function categoryReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.CATEGORY_UPDATE_FILTER_INDEX: {
      return {
        ...state,
        filterSelect: action.filterSelect
      };
    }
    default:
      return state;
  }
}
