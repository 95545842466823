import { actionTypes, loadBannerSuccess } from '@src/actions/bannerActions';
import { BannerApiService } from '@src/api/BannerApiServices';
import errorHandler from '@src/utils/errorHandler';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loadBanner(action: any) {
  const { screen, userType } = action.data;
  try {
    const { data }: AxiosResponse = yield call(
      BannerApiService.getBannerByscreen,
      screen, userType
    );
    yield put(loadBannerSuccess(data));
  } catch (err) {
    errorHandler(err);
  }
}

function* watchLoadBanner() {
  yield takeLatest(actionTypes.LOAD_BANNER, loadBanner);
}

export function* bannerSaga() {
  yield all([watchLoadBanner()]);
}
