import { actionTypes } from '@src/actions/lifeStyleActions';
import { ILifeStyleState } from '@src/interfaces/ILifeStyleState';

const initialState: ILifeStyleState = {
  currentIndexQuestion: 0,
  isLoadingListQuestions: false,
  listQuestions: [],
  listAnsweredQuestions: {}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lifeStyleReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOAD_LIFE_STYLE_QUESTIONS_SUCCESS: {
      return {
        ...state,
        listQuestions: action.data
      };
    }
    case actionTypes.SET_CURRENT_LIFE_STYLE_QUESTION: {
      return {
        ...state,
        currentIndexQuestion: action.data
      };
    }
    case actionTypes.ANSWER_LIFE_STYLE_QUESTION: {
      const { question, answer } = action.data;
      return {
        ...state,
        listAnsweredQuestions: {
          ...state.listAnsweredQuestions,
          [question.key]: {
            question_key: question.key,
            answer
          }
        }
      };
    }

    default:
      return state;
  }
}
