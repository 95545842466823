import { AxiosResponse } from 'axios';

import { appAxios } from './AppAxios';

export const InputApiServices = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createInput: async(params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('asset/transactions', params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
