import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { actionTypes } from '@src/actions/categoryActions';
import { setLoading } from '@src/actions/commonActions';
import errorHandler from '@src/utils/errorHandler';
import { CategoryApiServices } from '@src/api/CategoryApiServices';
import { loadParamSelector } from '@src/selectors/historySelector';
import { loadListHistorySuccess } from '@src/actions/historyActions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadCategoryTransactionWorker(action: any) {
  const { time, id } = action;
  const { account } = yield select(loadParamSelector);
  const params = {
    time,
    id,
    ...(account.id === 'all' ? null : { account_id: account.id })
  };

  try {
    yield put(setLoading(true));
    const { data }: AxiosResponse = yield call(CategoryApiServices.loadCategoryTransactions, params);
    const transactions = id === '' // Total Category
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ? data.transactions.filter((transaction: any) => !transaction.is_income)
      : data.transactions;

    yield put(loadListHistorySuccess({ transactions: transactions }));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

function * loadCategoryTransactionWatcher() {
  yield takeLatest(actionTypes.LOAD_CATEGORY_TRANSACTION, loadCategoryTransactionWorker);
}

export function * categorySaga() {
  yield all([
    loadCategoryTransactionWatcher()
  ]);
}

export { loadCategoryTransactionWorker };
