import { actionTypes } from '@src/actions/lifeplanActions';
import { ILifePlanState } from '@src/interfaces/ILifePlanState';

const initialState: ILifePlanState = {
  currentIndexQuestion: -1,
  isPendingLoadListQuestions: true,
  listQuestions: [],
  listAnsweredQuestions: {},
  lifePlanResultData: {
    isPending: false,
    lifePlaneResult: {
      products: [],
      life_balance_change: [],
      life_plan_results: [],
      lifetime_expense: null,
      lifetime_income: null,
      is_u60_or_u70: false,
      age_u: 0,
      asset_info: null
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lifeplanReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOAD_LIFE_PLAN_QUESTION_PENDING: {
      return {
        ...state,
        currentIndexQuestion: -1,
        listAnsweredQuestions: {},
        isPendingLoadListQuestions: true
      };
    }
    case actionTypes.LOAD_LIFE_PLAN_QUESTION_SUCCESS: {
      return {
        ...state,
        isPendingLoadListQuestions: false,
        listQuestions: action.data
      };
    }
    case actionTypes.UPDATE_LIST_ANSWERED_LIFE_PLAN_QUESTION: {
      return {
        ...state,
        listAnsweredQuestions: { ...action.data }
      };
    }

    case actionTypes.SET_CURRENT_LIFE_PLAN_QUESTION: {
      return {
        ...state,
        currentIndexQuestion: action.data
      };
    }
    case actionTypes.ANSWER_LIFE_PLAN_QUESTION: {
      const { question, answer } = action.data;
      return {
        ...state,
        listAnsweredQuestions: {
          ...state.listAnsweredQuestions,
          [question.key]: {
            question_key: question.key,
            answer
          }
        }
      };
    }

    case actionTypes.LOAD_LIFE_PLAN_RESULT_PENDING: {
      return {
        ...state,
        lifePlanResultData: {
          ...state.lifePlanResultData,
          isPending: true
        }
      };
    }
    case actionTypes.LOAD_LIFE_PLAN_RESULT_SUCCESS: {
      return {
        ...state,
        lifePlanResultData: {
          ...state.lifePlanResultData,
          isPending: false,
          lifePlaneResult: action.data
        }
      };
    }
    case actionTypes.LOAD_LIFE_PLAN_RESULT_ERRORED: {
      return {
        ...state,
        lifePlanResultData: {
          ...state.lifePlanResultData,
          isPending: false
        }
      };
    }

    default:
      return state;
  }
}
