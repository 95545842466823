import {
  actionTypes
} from '@src/actions/bannerActions';
import { IBannerState } from '@src/interfaces/IBannerState';

const initialState: IBannerState = {
  screen: '',
  redirect_url: '',
  thumbnail_url: ''
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function bannerReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOAD_BANNER_SUCCESS:
      return {
        ...state,
        ...action.asset
      };
    case actionTypes.CLEAR_BANNER:
      return {};
    default:
      return state;
  }
}
