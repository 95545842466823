import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import Router from 'next/router';
import { AxiosResponse } from 'axios';

import {
  actionTypes,
  loadLifePlanQuestionSuccess,
  setCurrentIndexLifePlanQuestion,
  loadLifePlanQuestionPending,
  updateListAnsweredQuestion,
  loadLifePlanResultPending,
  loadLifePlanResultSuccess,
  loadLifePlanResultErrored,
  answerLifePlanQuestion
} from '@src/actions/lifeplanActions';
import errorHandler from '@src/utils/errorHandler';
import { setLoading } from '@src/actions/commonActions';
import { checkIsShowQuestionByExpressionType } from '@src/utils/functionUtils';
import { LifePlanApiServices } from '@src/api/LifePlanApiServices';
import {
  listAnsweredLifePlanQuestionsSelector,
  currentIndexLifePlanQuestionSelector,
  listLifePlanQuestionsSelector
} from '@src/selectors/lifeplanSelector';
import { IQuestionGroupItem, IQuestion } from '@src/interfaces/IQuestion';
import { IAnsweredQuestionLifePlan } from '@src/interfaces/IAnswerQuestion';
import { loadUserInfo, updateUserInfo } from '@src/actions/userActions';
import { userInfoSeletor } from '@src/selectors/userSeletor';

function * loadLifePlanQuestionSaga() {
  try {
    yield put(loadLifePlanQuestionPending());
    const { data }: AxiosResponse = yield call(LifePlanApiServices.getLifePlanQuestions);
    yield put(loadLifePlanQuestionSuccess(data));
    yield put(setCurrentIndexLifePlanQuestion(0));
    const question_9: IQuestionGroupItem = data.find((q: IQuestionGroupItem) => q.order === 9);
    if (question_9 && question_9.questions) {
      const { questions } = question_9;
      const firstAnswer = questions[0].answers[0];
      const answerQuestion_9 = {
        question: questions[0],
        answer: firstAnswer
      };
      yield put(answerLifePlanQuestion(answerQuestion_9));
    }
  } catch (err) {
    errorHandler(err);
    yield put(loadLifePlanQuestionSuccess([]));
  }
}

function * submitAnsweredLifePlanQuestionSaga() {
  try {
    yield put(setLoading(true));
    const listAnsweredQuestions = yield select(listAnsweredLifePlanQuestionsSelector);
    const listQuestions = yield select(listLifePlanQuestionsSelector);
    const userInfo = yield select(userInfoSeletor);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const answers: any = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listQuestionSaved: any[] = [];

    for (const key in listAnsweredQuestions) {
      const currentValueAns: IAnsweredQuestionLifePlan = listAnsweredQuestions[key];
      if (currentValueAns) {
        const { question_key, answer } = currentValueAns;
        answers[question_key] = answer.value;
        if (answer.checkboxes && typeof answer.checkboxes === 'object') {
          for (const checkboxKey in answer.checkboxes) {
            answers[checkboxKey] = `${answer.checkboxes[checkboxKey]}`;
          }
        }
      }
    }
    listQuestions.forEach((questionGroupItem: IQuestionGroupItem) => {
      const { questions } = questionGroupItem;
      questions.forEach((question: IQuestion) => {
        const isShowQuestion: boolean = checkIsShowQuestionByExpressionType(question.conditional_expressions, listAnsweredQuestions);
        if (isShowQuestion && listAnsweredQuestions[question.key]) {
          listQuestionSaved.push(question);
        }
      });
    });
    yield call(LifePlanApiServices.submitLifePlanAnsweredQuestions, { answers, questions: listQuestionSaved });
    yield put(updateUserInfo({ ...userInfo, is_first_life_plan: false }));
    yield put(loadUserInfo());
    Router.push('/life_plan/');
  } catch (err) {
    yield put(setLoading(false));
    errorHandler(err);
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function * skipCurrentQuestionSaga(action: any) {
  const currentIndexQuestion = yield select(currentIndexLifePlanQuestionSelector);
  if (currentIndexQuestion <= 3) {
    return;
  }
  const listQuestions = yield select(listLifePlanQuestionsSelector);
  const listAnsweredQuestions = yield select(listAnsweredLifePlanQuestionsSelector);
  const questionGroupItem: IQuestionGroupItem | undefined = listQuestions.find((_: IQuestionGroupItem, index: number) => index === currentIndexQuestion);

  if (questionGroupItem) {
    const { questions } = questionGroupItem;
    let totalHideQuestion = 0;
    const questionKeysWillRemove: any = {};
    questions.forEach((question: IQuestion) => {
      const isShowQuestion: boolean = checkIsShowQuestionByExpressionType(question.conditional_expressions, listAnsweredQuestions);
      if (!isShowQuestion) {
        totalHideQuestion++;
        questionKeysWillRemove[question.key] = true;
      }
    });

    if (totalHideQuestion === questions.length) { // skip render question if all question is not show
      const newListAnsweredQuestions: any = {};
      // remove answered question exist if skip
      for (const questionKey in listAnsweredQuestions) {
        if (questionKeysWillRemove[questionKey]) continue;
        newListAnsweredQuestions[questionKey] = { ...listAnsweredQuestions[questionKey] };
      }

      yield put(updateListAnsweredQuestion(newListAnsweredQuestions));

      const willIndexQuestion = action.data;
      const isPrevQuestion = action.isPrevQuestion;

      if (isPrevQuestion) {
        yield put(setCurrentIndexLifePlanQuestion(willIndexQuestion - 1, true));
      }
      if (willIndexQuestion >= currentIndexQuestion) {
        yield put(setCurrentIndexLifePlanQuestion(willIndexQuestion + 1));
      }
    }
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

function * loadLifePlanResultSaga() {
  try {
    yield put(loadLifePlanResultPending());
    const { data, status }: AxiosResponse = yield call(LifePlanApiServices.getLifePlanResult);
    if (data && (status >= 200 && status < 300)) {
      yield put(loadLifePlanResultSuccess(data));
      return;
    }
    yield put(loadLifePlanResultErrored());
  } catch (error) {
    yield put(loadLifePlanResultErrored());
  }
}

function * watchLoadlifeplanQuestion() {
  yield takeLatest(actionTypes.LOAD_LIFE_PLAN_QUESTION, loadLifePlanQuestionSaga);
}
function * watchSubmitAnsweredQuestion() {
  yield takeLatest(actionTypes.SUBMIT_ANSWERED_LIFE_PLAN_QUESTION, submitAnsweredLifePlanQuestionSaga);
}
function * watchNextOrPrevQuestion() {
  yield takeLatest(actionTypes.SET_CURRENT_LIFE_PLAN_QUESTION, skipCurrentQuestionSaga);
}
function * watchLoadLifePlanResult() {
  yield takeLatest(actionTypes.LOAD_LIFE_PLAN_RESULT, loadLifePlanResultSaga);
}

export function * lifeplanSaga() {
  yield all([
    watchLoadlifeplanQuestion(),
    watchSubmitAnsweredQuestion(),
    watchNextOrPrevQuestion(),
    watchLoadLifePlanResult()
  ]);
}
