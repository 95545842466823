import App, { Container } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
// For build without es6 -- support ios8
import withRedux from 'next-redux-wrapper/lib/index';
import withReduxSaga from 'next-redux-saga';
import { appStore } from '../src/app/appStore';
import { checkTokenIsExpired } from '@src/utils/functionUtils';
import { loadUserInfo } from '@src/actions/userActions';
import { initGA } from '@src/utils/analytics';
import Cookies from 'js-cookie';
import Sentry from '@src/utils/sentry';
import './../src/utils/function.js';
import '../styles/bulma/_all.sass';
import 'react-day-picker/lib/style.css';
import 'rmc-picker/assets/index.css';
import '../styles/_all.scss';
import Head from 'next/head';

class MyApp extends App {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async getInitialProps({ Component, ctx }: any) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ctx });
    }

    return { pageProps };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const windowClient = window as any;
      if (!windowClient.GA_INITIALIZED) {
        initGA();
        windowClient.GA_INITIALIZED = true;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { store } = this.props as any;
    const vtkt = Cookies.get('VTKT') || Cookies.get('vtkt') || '';
    if (vtkt) {
      if (store && typeof store.dispatch === 'function') {
        this.handleLoadUserInfo();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, errorInfo: any) {
    // This is needed to render errors correctly in development / production
    const { captureException } = Sentry();

    captureException(error, { errorInfo: error, pathname: '', query: {} });
    if (typeof super.componentDidCatch === 'function') {
      super.componentDidCatch(error, errorInfo);
    }
  }

  handleLoadUserInfo = () => {
    const tokenIsExpired: boolean = checkTokenIsExpired();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { store } = this.props as any;
    if (!tokenIsExpired) {
      store.dispatch(loadUserInfo(false));
    }
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { Component, pageProps, store } = this.props as any;
    return (
      <Container>
        <Head>
          <meta charSet="utf-8" />
          <meta name="HandheldFriendly" content="true" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta content="telephone=no" name="format-detection" />
          <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0" />
          <meta httpEquiv="pragma" content="no-cache" />
          <meta httpEquiv="cache-control" content="no-cache" />
          <meta httpEquiv="expires" content="-1" />
          <meta name="help-header-icon-img" content="" />
          <meta name="help-header-icon-target-url" content="" />
        </Head>
        <Provider store={store}>

          <Component {...pageProps} />
        </Provider>
      </Container>
    );
  }
}

export default withRedux(appStore)(withReduxSaga({ async: true })(MyApp));
