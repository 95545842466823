import { actionTypes } from '@src/actions/userActions';
import { IUserState, IUserInfo } from '@src/interfaces/IUserState';
import { daysOfMonth, daysOfWeek } from '@src/static_data/picker_time';

export const defaultUserInfo: IUserInfo = {
  avatar_url: '',
  nick_name: '',
  user_name_kanji: '',
  life_style: '',
  is_first_login: false,
  is_first_life_plan: false,
  is_accept_policy: false,
  life_style_shorten_phrase: '',
  life_style_products: [],
  asset_info: { age: 65, savings: 0 },
  is_accept_webapp_term: false,
  is_accept_kabu_term: false,
  is_aggregated: false,
  is_hide_aggregation_tutorial: false,
  is_hide_history_tooltip: false,
  is_1month_unaggregated: false,
  is_first_crawl_done: false,
  life_plan_note: {
    id: '',
    title: '',
    analytics_advice: ''
  },
  report_setting: {
    month_start_day: daysOfMonth[0],
    week_start_day: daysOfWeek[0],
    holiday_mode: 0,
    is_included_ec: false
  }
};
const initialState: IUserState = {
  userInfo: { ...defaultUserInfo }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOAD_USER_INFO_SUCCESS: {
      const formatedInfoSetting = mapInitPickerValue(action.user.report_setting);

      return {
        ...state,
        userInfo: {
          ...action.user,
          report_setting: formatedInfoSetting
        }
      };
    }
    case actionTypes.UPDATE_USER_INFO: {
      const formatedInfoSetting = mapInitPickerValue(action.user.report_setting);

      return {
        userInfo: {
          ...state.userInfo,
          ...action.user,
          report_setting: formatedInfoSetting
        }
      };
    }
    case actionTypes.USER_UPDATE_ACCEPT_POLICY_SUCCESS: {
      return {
        userInfo: {
          ...state.userInfo,
          ...action.agree
        }
      };
    }
    case actionTypes.UPDATE_SHOW_TUTORIAL_AGGREGATION_SUCCESS: {
      return {
        userInfo: {
          ...state.userInfo,
          is_hide_aggregation_tutorial: true
        }
      };
    }
    case actionTypes.UPDATE_SHOW_HISTORY_TOOLTIP_SUCCESS: {
      return {
        userInfo: {
          ...state.userInfo,
          is_hide_history_tooltip: true
        }
      };
    }
    case actionTypes.UPDATE_STATE_REPORT_SETTING: {
      return {
        userInfo: {
          ...state.userInfo,
          report_setting: action.settingInfo
        }
      };
    }
    default:
      return state;
  }
}

function mapInitPickerValue(report_setting: any) {
  const month_start_day = daysOfMonth.find(item => item.val === report_setting.month_start_day) || { text: '1日', val: 0 };
  const week_start_day = daysOfWeek.find(item => item.val === report_setting.week_start_day) || { text: '月曜日', val: 0 };

  return {
    ...report_setting,
    month_start_day: month_start_day,
    week_start_day
  };
}
