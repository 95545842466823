import { appAxios } from './AppAxios';
import { AxiosResponse } from 'axios';

export const LoginLinkApiServices = {
  getLoginLink: async(id: string, redirectURI: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/au_link/login_url', {
        service_id: id.toString(),
        redirect_uri: redirectURI
      });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
