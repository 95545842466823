import { IBannerState } from '@src/interfaces/IBannerState';

export const actionTypes = {
  LOAD_BANNER: 'LOAD_BANNER',
  LOAD_BANNER_PENDING: 'LOAD_BANNER_PENDING',
  LOAD_BANNER_SUCCESS: 'LOAD_BANNER_SUCCESS',
  CLEAR_BANNER: 'CLEAR_BANNER'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadBanner(screen: string, userType?: string) {
  return {
    type: actionTypes.LOAD_BANNER,
    data: {
      screen,
      userType
    }
  };
}

export function loadBannerSuccess(asset: IBannerState) {
  return {
    type: actionTypes.LOAD_BANNER_SUCCESS,
    asset
  };
}

export function clearBanner() {
  return {
    type: actionTypes.CLEAR_BANNER
  };
}
