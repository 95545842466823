import { AxiosResponse } from 'axios';

import { appAxios } from './AppAxios';

export const HomeApiServices = {
  getAssetList: async(time: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/top', { params: { time: time } });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
