import { appAxios } from './AppAxios';
import { AxiosResponse } from 'axios';

export const BalanceApiServices = {
  getAuStockBalance: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/top/austocks');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getAuBalance: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/top/swallow');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getBalanceDetail: async(id: string, datetime: string) => {
    try {
      const params = { params: { time: datetime } };
      const { data }: AxiosResponse = await appAxios.get('/asset/detail/' + id, params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getTransactionsChart: async(account_ids: string, from: string, to: string, dataSource: number, mainAccountID: string) => {
    try {
      const params = { 'account_ids[]': account_ids, from, to, data_source: dataSource, main_account_id: mainAccountID };
      const { data }: AxiosResponse = await appAxios.get('/asset/transactions/chart', { params });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  updateBundleSettings: async(
    showFinancialPackages: boolean
  ) => {
    try {
      await appAxios.post('/asset/settings/saves', { message_settings: { show_financial_packages: showFinancialPackages } });
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getBundleInfo: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/settings/info');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
