import {
  actionTypes
} from '@src/actions/transactionActions';
import { ITransactionItem } from '@src/interfaces/ITransactionState';

export const initialTransactionState: ITransactionItem = {
  id: '',
  time: '',
  name: '',
  amount: '',
  account: {
    id: '',
    name: '',
    service_name: '',
    service_id: ''
  },
  note: '',
  flag_excluded_from_calculation: false,
  flag_combine: false,
  is_income: false,
  source: '',
  data_source: 4,
  store_name: '',
  service_name: '',
  member_type: '',
  account_id: '',
  fulltime: '',
  card_type: '',
  payment_reservation: ''
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transactionReducer(state = initialTransactionState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_TRANSACTION_INFO:
    case actionTypes.GET_TRANSACTION_INFO_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
}
