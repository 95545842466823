import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import Router from 'next/router';
import { show } from 'redux-modal';
import getConfig from 'next/config';
import { actionTypes } from '@src/actions/loginLinkActions';
import errorHandler from '@src/utils/errorHandler';
import { setLoading, updateSkipScrollTop } from '@src/actions/commonActions';
import { LoginLinkApiServices } from '@src/api/LoginLinkApiServices';
import { pathConstant, MODAL_NAME, MAINTENANCE_STATUS } from '@src/utils/appContanst';
import { getPathHasLastSlash, isAcceptPolicy, isShowMaintenanceModal } from '@src/utils/functionUtils';
import { userInfoSeletor } from '@src/selectors/userSeletor';
import { AccountApiServices } from '@src/api/AccountApiServices';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * getLoginLinkSaga(action: any) {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { DOMAIN, APP_DOMAIN, SUFFIX_SCHEMA } = publicRuntimeConfig;
  const { id, redirectURI } = action;

  try {
    yield put(updateSkipScrollTop(true));
    yield put(setLoading(true));

    const isAcceptedTermOfUse = isAcceptPolicy(yield select(userInfoSeletor));

    if (isAcceptedTermOfUse) {
      const redirectLink = redirectURI
        ? `${APP_DOMAIN}${SUFFIX_SCHEMA}${DOMAIN}${action.redirectURI}`
        : `${APP_DOMAIN}${DOMAIN}`;
      const {
        data,
        maintenance_status = MAINTENANCE_STATUS.NO_MAINTENANCE
      } = yield call(LoginLinkApiServices.getLoginLink, id, redirectLink);

      if (isShowMaintenanceModal(maintenance_status)) {
        yield put(show(MODAL_NAME.MAINTENANCE_MODAL));
      } else {
        const { redirect_path } = data.sso_account;
        window.location.href = redirect_path;
      }
    } else {
      const pathname = window.location.pathname === pathConstant.topPath
        ? pathConstant.topPath
        : getPathHasLastSlash(window.location.pathname);

      Router.push({
        pathname,
        query: {
          redirect_path: pathname,
          is_black_route: true,
          open_model_tutorial: true
        }
      });
    }
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * linkAccountSuccessSaga(action: any) {
  const { params } = action;

  try {
    yield put(setLoading(true));
    const { status } = yield call(AccountApiServices.postDataVerifyKabucom, params);

    if (status === 200) {
      Router.push(pathConstant.assetPath);
    }
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

function * watchGetBankLoginLink() {
  yield takeLatest(actionTypes.GET_LOGIN_LINK, getLoginLinkSaga);
}

function * watchLinkAccountSuccessSaga() {
  yield takeLatest(actionTypes.LINK_ACCOUNT_SUCCESS, linkAccountSuccessSaga);
}

export function * loginLinkSaga() {
  yield all([
    watchGetBankLoginLink(),
    watchLinkAccountSuccessSaga()
  ]);
}
