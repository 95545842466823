import { actionTypes } from '@src/actions/bookingActions';

import { IBookingState, IBookingInfo } from '@src/interfaces/IBookingState';

export const defaultBookingInfo: IBookingInfo = {
  timestamp: '20190305',
  customer_id: 'NULL',
  customer_name: '',
  tel_no: '',
  post_no: '',
  address: '',
  mail: '',
  unified_store_cd: 'NULL',
  hub_cd: '000',
  operator_id: 'FPM',
  subscr_cd: '',
  shop_name: 'FPM',
  operator_name: 'FPM',
  customer_name_kana: '',
  birth_date: '',
  reserv_no: 'NULL'
};

const initialState: IBookingState = {
  bookingInfo: { ...defaultBookingInfo }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function bookingReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.POST_BOOKING_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data
      };
    }
    default:
      return state;
  }
}
