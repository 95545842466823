import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

import { appReducer } from '@src/reducers/appReducer';
import { appSaga } from '@src/sagas/appSaga';
import { appAxios } from '@src/api/AppAxios';
import { setErrorMessage } from '@src/actions/commonActions';
import { IAppState } from '@src/interfaces/IAppState';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createAxiosMiddleware = (axios: any) => ({ dispatch, getState }: any) => {
  axios.interceptors.request.use(
    function(config: AxiosRequestConfig) {
      if (typeof getState === 'function') {
        const state: IAppState = getState();
        if (state && state.commonState) {
          const { statusCode } = state.commonState.errorMessage;
          if (statusCode && +statusCode > 0) {
            dispatch(setErrorMessage({ title: '', message: '', statusCode: '' }));
          }
        }
      }
      if (typeof sessionStorage && sessionStorage.getItem('token')) {
        config.headers.Authorization = sessionStorage.getItem('token');
      }
      return config;
    },
    function(error: AxiosError) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function(response: AxiosResponse) {
      return response;
    },
    function(error: AxiosError) {
      if (error && error.response && error.response.status) {
        const { status } = error.response;

        dispatch(setErrorMessage({ title: '', message: '', statusCode: status }));
      }
      return Promise.reject(error);
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (next: any) => (action: any) => next(action);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appStore(initialState: any = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const axiosMiddleware = createAxiosMiddleware(appAxios);
  const store = createStore(
    appReducer,
    initialState,
    bindMiddleware([sagaMiddleware, axiosMiddleware])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(appSaga);
  };

  store.runSagaTask();
  return store;
}
