
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultSetting: any = { };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  settingInfo: { ...defaultSetting }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function settingReducer(state = initialState, action: any) {
  switch (action.type) {
    default:
      return state;
  }
}
