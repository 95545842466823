import { actionTypes } from '@src/actions/commonActions';
import { ICommonState } from '@src/interfaces/ICommonState';

const initialState: ICommonState = {
  isLoading: false,
  isEditing: false,
  skipScrollTop: false,
  isLoadingAsset: false,
  isOpenSettingProfile: false,
  authentication: {
    isAuthenticating: true,
    messageAuthentication: '',
    isAuthenSuccess: false
  },
  reLifeStyle: false,
  errorMessage: {
    title: '',
    message: '',
    statusCode: ''
  },
  isOpenInput: false,
  filterSelect: {
    id: '1',
    label: '履歴が新しい順',
    value: ''
  },
  activeTab: 1
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function commonReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.COMMON_SET_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      };
    }
    case actionTypes.COMMON_TOGGLE_MODAL_SETTING_PROFILE: {
      return {
        ...state,
        isOpenSettingProfile: action.isOpenSettingProfile
      };
    }
    case actionTypes.COMMON_TOGGLE_SET_AUTHENTICATING: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          ...action.data
        }
      };
    }
    case actionTypes.COMMON_SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.data
      };
    }
    case actionTypes.COMMON_RE_SERVEY_LIFE_STYLE: {
      return {
        ...state,
        reLifeStyle: action.reLifeStyle
      };
    }

    case actionTypes.COMMON_SET_LOADING_ASSET: {
      return {
        ...state,
        isLoadingAsset: action.isLoadingAsset
      };
    }

    case actionTypes.COMMON_TOGGLE_MODAL_INPUT: {
      return {
        ...state,
        isOpenInput: action.isOpenInput
      };
    }
    case actionTypes.UPDATE_FILTER:
      return {
        ...state,
        filterSelect: action.filterSelect
      };
    case actionTypes.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };

    case actionTypes.UPDATE_SKIP_SCROLL_TOP:
      return {
        ...state,
        skipScrollTop: action.skipScrollTop
      };

    case actionTypes.UPDATE_EDITING_STATUS:
      return {
        ...state,
        isEditing: action.isEditing
      };
    default:
      return state;
  }
}
