import { AxiosResponse } from 'axios';
import { appAxios } from './AppAxios';

export const LifePlanApiServices = {
  getLifePlanQuestions: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/lifeplan/questions');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitLifePlanAnsweredQuestions: async(params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/lifeplan/answers', params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getLifePlanResult: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/lifeplan/result');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
