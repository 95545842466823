import { IAppState } from '@src/interfaces/IAppState';

export const columnStateSeletor = (appState: IAppState) => (
  appState.columnState
);

export const columnAllSeletor = (appState: IAppState) => (
  appState.columnState.all
);

export const columnBookmarkSeletor = (appState: IAppState) => (
  appState.columnState.bookmarks
);

export const columnsPickupSeletor = (appState: IAppState) => (
  appState.columnState.pickups
);

export const columnsRakinksSeletor = (appState: IAppState) => (
  appState.columnState.rankings
);

export const columnDetailsSeletor = (appState: IAppState) => (
  appState.columnState.details
);
export const columnDetailColumnSeletor = (appState: IAppState) => (
  appState.columnState.details.detail
);
