import { AxiosResponse } from 'axios';

import { appAxios } from './AppAxios';

export const ColumnsApiServices = {
  getListAllColumns: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/columns');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getColumnsPickup: async(limit: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/column/pickup', { params: { limit: limit } });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getColumnsRanking: async(limit: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/column/ranking', { params: { limit: limit } });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getColumnsAll: async(paging_key?: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/column/all',
        { params: paging_key ? { paging_key } : undefined });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getColumnsBookmarks: async(paging_key?: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/column/bookmarks',
        { params: paging_key ? { paging_key } : undefined });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getColumnDetail: async(column_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/column/${column_id}`);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  bookmarkColumn: async(column_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/column/bookmarks', { article_id: column_id });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  deleteBookmarkColumn: async(column_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/column/bookmarks/delete',
        { article_id: column_id });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  readColumn: async(column_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/column/read', { article_id: column_id });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }

};
