import { IHomeInfo } from '@src/interfaces/IHomeState';

export const actionTypes = {
  LOAD_DATA_ASSET_LIST: 'LOAD_DATA_ASSET_LIST',
  LOAD_DATA_ASSET_LIST_PENDING: 'LOAD_DATA_ASSET_LIST_PENDING',
  LOAD_DATA_ASSET_LIST_SUCCESS: 'LOAD_DATA_ASSET_LIST_SUCCESS'

};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadAssetList(params: any) {
  return {
    type: actionTypes.LOAD_DATA_ASSET_LIST,
    data: {
      params
    }
  };
}

export function loadAssetListSuccess(asset: IHomeInfo) {
  return {
    type: actionTypes.LOAD_DATA_ASSET_LIST_SUCCESS,
    asset
  };
}
