import { appAxios } from './AppAxios';
import { AxiosResponse } from 'axios';
import { ISettingInfo } from '@src/interfaces/IReportState';

export const reportAPIServices = {
  getReportData: async(reportType: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/report', { params: { type: reportType } });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  updateReportSettingData: async(settingInfo: ISettingInfo) => {
    const { holiday_mode, month_start_day, week_start_day, is_included_ec } = settingInfo;
    try {
      const { data }: AxiosResponse = await appAxios.post('/user/info', {
        report_setting: {
          holiday_mode,
          month_start_day: month_start_day.val,
          week_start_day: week_start_day.val,
          is_included_ec
        }
      });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
