export const actionTypes = {
  LOAD_CATEGORY_TRANSACTION: 'LOAD_CATEGORY_TRANSACTION',
  CATEGORY_UPDATE_FILTER_INDEX: 'CATEGORY_UPDATE_FILTER_INDEX'
};

export const loadCategoryTransaction = (id: string, time: string) => ({
  type: actionTypes.LOAD_CATEGORY_TRANSACTION,
  id,
  time
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function categoryUpdateFilterIndex(filterSelect: any) {
  return {
    type: actionTypes.CATEGORY_UPDATE_FILTER_INDEX,
    filterSelect
  };
}
