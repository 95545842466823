import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  actionTypes, loadDataAccountSuccess, loadDataAccountPending,
  loadDataAccountDetailSuccess, loadDataAccountDetail,
  loadDataAccountListSuccess
} from '@src/actions/accountActions';
import { setLoading, setLoadingAsset } from '@src/actions/commonActions';
import errorHandler from '@src/utils/errorHandler';
import { AccountApiServices } from '@src/api/AccountApiServices';
import { hide, show } from 'redux-modal';
import { isFirstCrawlDoneSelector } from '@src/selectors/userSeletor';
import { MAINTENANCE_STATUS, MODAL_NAME, pathConstant } from '@src/utils/appContanst';
import { removeUnusedAccountInStore } from '@src/actions/balanceActions';
import { getAndRemovePrevRoute, isShowMaintenanceModal, removeUnusedAccountInLocalStorage } from '@src/utils/functionUtils';
import Router from 'next/router';

function * loadAccountSaga() {
  try {
    yield put(setLoading(true));
    yield put(loadDataAccountPending());
    const { data, maintenance_status = MAINTENANCE_STATUS.NO_MAINTENANCE } = yield call(AccountApiServices.loadAccount);
    yield put(loadDataAccountSuccess({ ...data, maintenance_status }));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadAccountListSaga() {
  const isFirstCrawlDone: boolean = yield select(isFirstCrawlDoneSelector);
  const showAssetLoading = !isFirstCrawlDone;
  try {
    if (showAssetLoading) {
      yield put(setLoadingAsset(true));
    } else {
      yield put(setLoading(true));
    }

    const { data }: AxiosResponse = yield call(AccountApiServices.loadAccountList);
    yield put(loadDataAccountListSuccess(data));
    if (showAssetLoading) {
      yield put(setLoadingAsset(false));
    } else {
      yield put(setLoading(false));
    }
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadAccountDetailSaga(action: any) {
  try {
    yield put(setLoading(true));
    const { id } = action;
    const { data, maintenance_status = MAINTENANCE_STATUS.NO_MAINTENANCE } = yield call(AccountApiServices.loadAccountDetail, id);

    yield put(loadDataAccountDetailSuccess({ ...data, maintenance_status }));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * hideAccountSaga(action: any) {
  try {
    yield put(setLoading(true));
    const { id, is_hide } = action;
    const paramsHideAccount = {
      is_hide: is_hide
    };
    const { status }: AxiosResponse = yield call(AccountApiServices.updateAccount, id, paramsHideAccount);
    if (status >= 200 && status < 300) {
      yield put(setLoading(false));
      yield put(loadDataAccountDetail(id));
      yield put(hide('DISCONNECT_MODAL'));
      yield put(hide('UNHIDE_MODAL'));
      return;
    }
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * deleteAccountSaga(action: any) {
  try {
    yield put(setLoading(true));
    const { id } = action;
    const { status, maintenance_status = MAINTENANCE_STATUS.NO_MAINTENANCE } = yield call(AccountApiServices.deleteAccount, id);

    yield put(setLoading(false));
    yield put(hide('DELETE_MODAL'));
    yield call(removeUnusedAccountInLocalStorage, id);
    yield put(removeUnusedAccountInStore(id));
    if (isShowMaintenanceModal(maintenance_status)) {
      yield put(show(MODAL_NAME.MAINTENANCE_MODAL));
    } else if (status >= 200 && status < 300) {
      getAndRemovePrevRoute();
      if (window.location.pathname.includes(pathConstant.asset)) {
        Router.push(pathConstant.topPath);
      } else {
        Router.push('/accounts/');
      }
    }
  } catch (err) {
    errorHandler(err);
  }
}

function * watchloadDeleteAccountSaga() {
  yield takeLatest(actionTypes.DELETE_ACCOUNT, deleteAccountSaga);
}

function * watchloadAccountSaga() {
  yield takeLatest(actionTypes.LOAD_ACCOUNT, loadAccountSaga);
}

function * watchloadAccountDetailSaga() {
  yield takeLatest(actionTypes.LOAD_ACCOUNT_DETAIL, loadAccountDetailSaga);
}

function * watchloadHideAccountSaga() {
  yield takeLatest(actionTypes.HIDE_ACCOUNT, hideAccountSaga);
}
function * watchloadAccountListSaga() {
  yield takeLatest(actionTypes.LOAD_ACCOUNT_LIST, loadAccountListSaga);
}

export function * accountSaga() {
  yield all([
    watchloadAccountSaga(),
    watchloadAccountDetailSaga(),
    watchloadHideAccountSaga(),
    watchloadAccountListSaga(),
    watchloadDeleteAccountSaga()
  ]);
}
