import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes, loadListHistorySuccess, loadStockProductSuccess } from '@src/actions/historyActions';
import { setLoading, setLoadingAsset } from '@src/actions/commonActions';
import errorHandler from '@src/utils/errorHandler';
import { HistoryApiServices } from '@src/api/HistoryApiServices';
import { UserApiServices } from '@src/api/UserApiServices';
import { AccountType } from '@src/utils/appContanst';
import { isFirstCrawlDoneSelector } from '@src/selectors/userSeletor';
import { formatDate } from '@src/utils/dateTime';
import { loadUserInfo } from '@src/actions/userActions';
import { ILatestHistoriesParameter } from '@src/interfaces/IHistoryAction';
import { handleEnableLoadingModal, handleDisableLoadingModal } from './commonSaga';
import { AccountApiServices } from '@src/api/AccountApiServices';
import _find from 'lodash.find';
import { IAccountItem } from '@src/interfaces/IAccountState';
import { getAccountType, groupStockProducts } from '@src/utils/functionUtils';
import { IHistoryState } from '@src/interfaces/IHistoryState';

function * loadHistorySaga(action: {type: string, params: {account_id: string, time: string}}) {
  const { params } = action;
  const isFirstCrawlDone: boolean = yield select(isFirstCrawlDoneSelector);
  const showAssetLoading = !isFirstCrawlDone;
  const { time } = params;

  // time parameter hasn't value => time is current YYYY-MM
  params.time = time || formatDate(true);
  try {
    if (!showAssetLoading) {
      yield put(setLoading(true));
    }
    const { data }: AxiosResponse = yield call(HistoryApiServices.getHistory, params);
    yield put(loadListHistorySuccess(data));
    if (showAssetLoading) {
      yield call(UserApiServices.updateUserInfo, { is_first_crawl_done: true });
      yield put(loadUserInfo(false));
      yield put(setLoadingAsset(false));
    } else {
      yield put(setLoading(false));
    }
  } catch (err) {
    errorHandler(err);
  }
}

function * loadAllHistoriesSaga(action: {type: string, params: {account_id: string, time: string}}) {
  const { params } = action;
  const isFirstCrawlDone: boolean = yield select(isFirstCrawlDoneSelector);
  const showAssetLoading = !isFirstCrawlDone;
  const { time } = params;

  // time parameter hasn't value => time is current YYYY-MM
  params.time = time || formatDate(true);
  try {
    if (!showAssetLoading) {
      yield put(setLoading(true));
    }
    const [transactionHistories, accounts]: Array<AxiosResponse> = yield all([
      call(HistoryApiServices.getHistory, params),
      call(AccountApiServices.loadAccountList)
    ]);
    let transactions = transactionHistories.data.transactions;
    const au_ponta = _find(accounts.data, (item: IAccountItem) => AccountType.au_ponta === getAccountType(item.data_source));
    if (au_ponta) {
      const { data }: AxiosResponse = yield call(HistoryApiServices.getHistoryTransactionAuPonta, { ...params, account_id: au_ponta ? au_ponta.id : '' });
      transactions = transactionHistories.data.transactions.concat(data.transactions);
    }

    yield put(loadListHistorySuccess({ transactions }));
    if (showAssetLoading) {
      yield call(UserApiServices.updateUserInfo, { is_first_crawl_done: true });
      yield put(loadUserInfo(false));
      yield put(setLoadingAsset(false));
    } else {
      yield put(setLoading(false));
    }
  } catch (err) {
    errorHandler(err);
  }
}

function * getDataAuPayHistorySaga(action: {type: string, time: string, service: string}) {
  const { time, service } = action;

  try {
    yield put(setLoading(true));
    const { data }: AxiosResponse = yield call(HistoryApiServices.getAuPayHistory, { time: time || formatDate(true), service });
    const { history } = data;
    const transactions = history ? history.transactions || [] : [];

    yield put(loadListHistorySuccess({ transactions: transactions }));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

export function * loadLatestHistoriesSaga(action: { type: string, params: ILatestHistoriesParameter}) {
  const { params } = action;
  const { useLoadingModal = true, data_sources = [] } = params;
  // 14 - au-stock, 15 - mf-stock
  const isStock = data_sources.includes(14) || data_sources.includes(15);

  try {
    yield handleEnableLoadingModal(useLoadingModal);
    if (isStock) {
      const { data }:AxiosResponse = yield call(HistoryApiServices.getLatestStockProducts, params);
      const stocks = data.stocks || [];
      const products = groupStockProducts(stocks);

      yield put(loadStockProductSuccess(stocks, products));
    } else {
      const data: IHistoryState = yield call(HistoryApiServices.getLatestHistories, params);

      yield put(loadListHistorySuccess(data));
    }
    yield handleDisableLoadingModal(useLoadingModal);
  } catch (err) {
    errorHandler(err);
  }
}

function * watchLoadHistory() {
  yield takeLatest(actionTypes.LOAD_HISTORY, loadHistorySaga);
}

function * watchLoadAllHistories() {
  yield takeLatest(actionTypes.LOAD_ALL_HISTORIES, loadAllHistoriesSaga);
}

function * watchGetDataAuPayHistory() {
  yield takeLatest(actionTypes.GET_DATA_AU_PAY_HISTORY, getDataAuPayHistorySaga);
}

export function * historySaga() {
  yield all([
    watchLoadHistory(),
    watchLoadAllHistories(),
    watchGetDataAuPayHistory()
  ]);
}

export { loadAllHistoriesSaga };
