import { IBookingInfo } from '@src/interfaces/IBookingState';
export const actionTypes = {
  POST_BOOKING_DATA: 'POST_BOOKING_DATA',
  POST_BOOKING_DATA_PENDING: 'POST_BOOKING_DATA_PENDING',
  POST_BOOKING_DATA_SUCCESS: 'POST_BOOKING_DATA_SUCCESS'
};

export function postBookingData(data: IBookingInfo) {
  return {
    type: actionTypes.POST_BOOKING_DATA,
    data
  };
}

export function postBookingDataPending() {
  return {
    type: actionTypes.POST_BOOKING_DATA_PENDING
  };
}

export function postBookingDataSucces(data: IBookingInfo) {
  return {
    type: actionTypes.POST_BOOKING_DATA_SUCCESS,
    data
  };
}
