import { AxiosResponse } from 'axios';
import { appAxios } from './AppAxios';

export const CategoryApiServices = {
  loadCategoryTransactions: async(params: {time: string, id: string, account_id?: string}) => {
    try {
      const { id, ...restParams } = params;
      const { data }: AxiosResponse = await appAxios.get('/asset/transactions', {
        params: {
          ...(id ? { category_id: id } : null),
          ...restParams
        }
      });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
