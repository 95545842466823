import {
  actionTypes
} from '@src/actions/homeActions';
import { IHomeState, IHomeInfo } from '@src/interfaces/IHomeState';

const defaultHomeInfo: IHomeInfo = {
  balance: 0,
  remain_day: 0,
  total_income: 0,
  total_expense: 0,
  variable_cost: 0,
  fixed_cost: 0,
  time: '',
  categories: []
};

const initialState: IHomeState = {
  assetInfo: { ...defaultHomeInfo }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function homeReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOAD_DATA_ASSET_LIST_SUCCESS:
      return {
        ...state,
        assetInfo: {
          ...action.asset
        }
      };
    default:
      return state;
  }
}
