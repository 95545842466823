import { IListAnsweredQuestions } from '@src/interfaces/ILifePlanState';
import { ILifePlanResult } from '@src/interfaces/ILifePlanResult';
import { IQuestionGroupItem } from '@src/interfaces/IQuestion';
import { IAnsweredQuestionAction } from '@src/interfaces/IAnswerQuestion';

export const actionTypes = {
  LOAD_LIFE_PLAN_QUESTION: 'LOAD_LIFE_PLAN_QUESTION',
  LOAD_LIFE_PLAN_QUESTION_PENDING: 'LOAD_LIFE_PLAN_QUESTION_PENDING',
  LOAD_LIFE_PLAN_QUESTION_SUCCESS: 'LOAD_LIFE_PLAN_QUESTION_SUCCESS',
  SUBMIT_ANSWERED_LIFE_PLAN_QUESTION: 'SUBMIT_ANSWERED_LIFE_PLAN_QUESTION',
  ANSWER_LIFE_PLAN_QUESTION: 'ANSWER_LIFE_PLAN_QUESTION',
  SET_CURRENT_LIFE_PLAN_QUESTION: 'SET_CURRENT_LIFE_PLAN_QUESTION',
  UPDATE_LIST_ANSWERED_LIFE_PLAN_QUESTION: 'UPDATE_LIST_ANSWERED_LIFE_PLAN_QUESTION',
  LOAD_LIFE_PLAN_RESULT: 'LOAD_LIFE_PLAN_RESULT',
  LOAD_LIFE_PLAN_RESULT_PENDING: 'LOAD_LIFE_PLAN_RESULT_PENDING',
  LOAD_LIFE_PLAN_RESULT_SUCCESS: 'LOAD_LIFE_PLAN_RESULT_SUCCESS',
  LOAD_LIFE_PLAN_RESULT_ERRORED: 'LOAD_LIFE_PLAN_RESULT_ERRORED'
};

export function loadLifePlanQuestion() {
  return {
    type: actionTypes.LOAD_LIFE_PLAN_QUESTION
  };
}

export function loadLifePlanQuestionPending() {
  return {
    type: actionTypes.LOAD_LIFE_PLAN_QUESTION_PENDING
  };
}

export function loadLifePlanQuestionSuccess(questions: IQuestionGroupItem[]) {
  return {
    type: actionTypes.LOAD_LIFE_PLAN_QUESTION_SUCCESS,
    data: questions
  };
}

export function setCurrentIndexLifePlanQuestion(currentIndexQuestion: number, isPrevQuestion?: boolean) {
  return {
    type: actionTypes.SET_CURRENT_LIFE_PLAN_QUESTION,
    data: currentIndexQuestion,
    isPrevQuestion: isPrevQuestion || false
  };
}

export function answerLifePlanQuestion({ question, answer }: IAnsweredQuestionAction) {
  return {
    type: actionTypes.ANSWER_LIFE_PLAN_QUESTION,
    data: {
      question,
      answer
    }
  };
}

export function submitAnsweredLifePlanQuestion() {
  return {
    type: actionTypes.SUBMIT_ANSWERED_LIFE_PLAN_QUESTION
  };
}

export function updateListAnsweredQuestion(listAnsweredQuestions: IListAnsweredQuestions) {
  return {
    type: actionTypes.UPDATE_LIST_ANSWERED_LIFE_PLAN_QUESTION,
    data: listAnsweredQuestions
  };
}

export function loadLifePlanResult() {
  return {
    type: actionTypes.LOAD_LIFE_PLAN_RESULT
  };
}

export function loadLifePlanResultPending() {
  return {
    type: actionTypes.LOAD_LIFE_PLAN_RESULT_PENDING
  };
}
export function loadLifePlanResultSuccess(life_plan_result: ILifePlanResult) {
  return {
    type: actionTypes.LOAD_LIFE_PLAN_RESULT_SUCCESS,
    data: life_plan_result
  };
}
export function loadLifePlanResultErrored() {
  return {
    type: actionTypes.LOAD_LIFE_PLAN_RESULT_ERRORED
  };
}
