import {
  actionTypes
} from '@src/actions/inputActions';
import { IInputItem } from '@src/interfaces/IInputState';

export const initialExpenseState: IInputItem = {
  amount: null,
  time: '',
  note: '',
  isError: {
    amount: false,
    note: false
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function expenseReducer(state = initialExpenseState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_DATA_FIELD:
    {
      const { name, value } = action;
      return {
        ...state,
        [name]: value
      };
    }
    case actionTypes.CLEAR_DATA_EXPENSE:
    {
      const { data } = action;
      return {
        ...state,
        amount: data.amount,
        note: data.note,
        time: data.time,
        category: data.category
      };
    }
    case actionTypes.VALIDATE_EXPENSE_ERROR:
    {
      const { key, isError } = action;
      return {
        ...state,
        isError: {
          ...state.isError,
          [key]: isError
        }
      };
    }
    case actionTypes.VALIDATE_EXPENSE_PASS:
      return {
        ...state,
        error: false
      };
    default:
      return state;
  }
}
