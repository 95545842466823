import { AxiosResponse } from 'axios';

import { appAxios } from './AppAxios';
import { IKabucomAuth } from '@src/interfaces/IAccountState';

export const AccountApiServices = {
  deleteAccount: async(id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`/asset/accounts/${id}/delete`);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  loadAccount: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/accounts');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  loadAccountDetail: async(id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/asset/accounts/${id}`);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateAccount: async(id: string, params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`/asset/accounts/${id}/edit`, params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  loadAccountList: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/accounts/list');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  postDataVerifyKabucom: async(params: IKabucomAuth) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/asset/auth_kabucom/login', params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
