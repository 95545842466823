import axios from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { API_URL } = publicRuntimeConfig;

const baseURL = API_URL;

const CancelToken = axios.CancelToken;
export const sourceCancel = CancelToken.source();

const instanceAppAxios = axios.create({
  baseURL,
  cancelToken: sourceCancel.token
});

// Add a response interceptor
instanceAppAxios.interceptors.response.use(function(response) {
  // Do something with response data
  return response;
}, function(error) {
  return Promise.reject(error);
});

export const setHeaderAppAxios = (token?: string) => {
  if (!token) {
    if (sessionStorage && sessionStorage.getItem('token')) {
      instanceAppAxios.defaults.headers.common = {
        Authorization: `${sessionStorage.getItem('token')}`
      };
    }
  } else {
    instanceAppAxios.defaults.headers.common = {
      Authorization: token
    };
  }
};

export { instanceAppAxios as appAxios };
