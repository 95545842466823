import { AxiosResponse, AxiosError } from 'axios';
import moment from '@src/utils/kpfmMoment';
import _filter from 'lodash.filter';

import { appAxios } from './AppAxios';
import { ILatestHistoriesParameter } from '@src/interfaces/IHistoryAction';
import { ITransactionItem } from '@src/interfaces/ITransactionState';
import { getTimeFromTimeServer } from '@src/utils/format';

export const HistoryApiServices = {
  getHistory: async(params: {account_id: string, time: string}) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/transactions', { params });
      return data;
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        if (err.response.status === 404) {
          window.location.href = '/history/all/';
        }
      }
      error.stack = new Error().stack;
      throw error;
    }
  },

  getHistoryTransactionAuPonta: async(params: {account_id: string, time: string}) => {
    try {
      const query = `?time=${params.time}&account_ids[]=${params.account_id}`;
      const { data }: AxiosResponse = await appAxios.get(`/asset/transactions/points${query}`);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        if (err.response.status === 404) {
          window.location.href = '/history/all/';
        }
      }
      error.stack = new Error().stack;
      throw error;
    }
  },
  /**
   * call API to get transation in latest 30 days need:
   * 1.call API /transaction 2 times to get transations in latest 2 months.
   * 2.filter transactions a month ago to get transations in latest 30 days.
   */
  getLatestHistories: async(params: ILatestHistoriesParameter) => {
    try {
      const { account_id = '', latest_day_limit = 0, data_sources = [] } = params;
      const currentYYYYMM = moment().format('YYYY-MM');
      const thirtyDayagoDateTime = moment().subtract(latest_day_limit, 'days');
      const thirtyDayagoYYYYMM = thirtyDayagoDateTime.format('YYYY-MM');
      const thirtyDayagoYYYYMMDD = thirtyDayagoDateTime.format('YYYY-MM-DD');
      let dataSourcesParameter = '';
      let paramsAPI = '';

      data_sources.forEach((dataSource: number) => {
        dataSourcesParameter = `${dataSourcesParameter}&data_source[]=${dataSource}`;
      });
      paramsAPI = `&account_id=${account_id}${dataSourcesParameter}`;
      // incase 30 lastest days is in 2 months (current + prev) => call 2 API
      const getCurrentHistories = await appAxios.get(`/asset/transactions?time=${currentYYYYMM}${paramsAPI}`);
      const getPastHistories = (thirtyDayagoYYYYMM !== currentYYYYMM)
        ? await appAxios.get(`/asset/transactions?time=${thirtyDayagoYYYYMM}${paramsAPI}`)
        : new Promise((resolve) => resolve());
      const data = await Promise.all([getCurrentHistories, getPastHistories]);
      const currentTransactions = data[0].data.data.transactions;
      const pastTransactions = data[1] ? data[1].data.data.transactions : [];
      const transactions = _filter(pastTransactions, (transaction: ITransactionItem) => {
        const { year = '', month = '', day = '' } = getTimeFromTimeServer(transaction.time);
        const timeYYYYMMDD = `${year}-${month}-${day}`;

        return timeYYYYMMDD >= thirtyDayagoYYYYMMDD;
      });

      return { transactions: currentTransactions.concat(transactions) };
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        if (err.response.status === 404) {
          window.location.href = '/history/all/';
        }
      }
      error.stack = new Error().stack;
      throw error;
    }
  },

  getLatestStockProducts: async(params: ILatestHistoriesParameter) => {
    try {
      const { account_id = '', data_sources = [] } = params;
      let dataSourcesParameter = '';

      data_sources.forEach((dataSource: number) => {
        dataSourcesParameter = `${dataSourcesParameter}&data_source[]=${dataSource}`;
      });
      const { data } = await appAxios.get(`/asset/transactions?time=&account_id=${account_id}${dataSourcesParameter}`);

      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getAuPayHistory: async(params: {service: string, time: string}) => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/aupay/transactions', { params });
      return data;
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        if (err.response.status === 404) {
          window.location.href = '/history/all/';
        }
      }
      error.stack = new Error().stack;
      throw error;
    }
  }
};
