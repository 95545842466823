import { IAnswerQuestion } from '@src/interfaces/IAnswerQuestion';
import { IQuestion } from '@src/interfaces/IQuestion';

export const actionTypes = {
  LOAD_LIFE_STYLE_QUESTIONS: 'LOAD_LIFE_STYLE_QUESTIONS',
  LOAD_LIFE_STYLE_QUESTIONS_PENDING: 'LOAD_LIFE_STYLE_QUESTIONS_PENDING',
  LOAD_LIFE_STYLE_QUESTIONS_SUCCESS: 'LOAD_LIFE_STYLE_QUESTIONS_SUCCESS',
  SUBMIT_ANSWERED_LIFE_STYLE_QUESTION: 'SUBMIT_ANSWERED_LIFE_STYLE_QUESTION',
  ANSWER_LIFE_STYLE_QUESTION: 'ANSWER_LIFE_STYLE_QUESTION',
  SET_CURRENT_LIFE_STYLE_QUESTION: 'SET_CURRENT_LIFE_STYLE_QUESTION'
};

export function loadLifeStyleQuestions() {
  return {
    type: actionTypes.LOAD_LIFE_STYLE_QUESTIONS
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadLifeStyleQuestionsSuccess(questions: any[]) {
  return {
    type: actionTypes.LOAD_LIFE_STYLE_QUESTIONS_SUCCESS,
    data: questions
  };
}
export function loadLifeStyleQuestionsPending() {
  return {
    type: actionTypes.LOAD_LIFE_STYLE_QUESTIONS_PENDING
  };
}

export function setCurrentIndexLifeStyleQuestion(currentIndexQuestion: number) {
  return {
    type: actionTypes.SET_CURRENT_LIFE_STYLE_QUESTION,
    data: currentIndexQuestion
  };
}

export function answerLifeStyleQuestion(question: IQuestion, answer: IAnswerQuestion) {
  return {
    type: actionTypes.ANSWER_LIFE_STYLE_QUESTION,
    data: {
      question,
      answer
    }
  };
}
export function submitAnsweredLifleStyleQuestion() {
  return {
    type: actionTypes.SUBMIT_ANSWERED_LIFE_STYLE_QUESTION
  };
}
