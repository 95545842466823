import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes, getTransactionInfoSuccess, updateTransactionInfo } from '@src/actions/transactionActions';
import { setLoading, updateEditingStatus } from '@src/actions/commonActions';
import { TransactionApiServices } from '@src/api/TransactionApiServices';
import errorHandler from '@src/utils/errorHandler';

import Router from 'next/router';
import moment from '@src/utils/kpfmMoment';
import { getAndRemovePrevRoute } from '@src/utils/functionUtils';
import { getQueryVariable } from '@src/utils/domUtil';
import { updateTransactionList, removeItemInTransactionList } from '@src/actions/historyActions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * getTransactionInfoSaga(action: any) {
  try {
    const { id } = action;
    yield put(setLoading(true));
    const { data } : AxiosResponse = yield call(TransactionApiServices.getTransactionInfo, id);
    yield put(getTransactionInfoSuccess(data));
    yield put(updateTransactionInfo(data));
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * updateTransactionSaga(action: any) {
  try {
    yield put(setLoading(true));
    const { data, removeItemInTransactions } = action;
    const { id } = data;
    const params = {
      account_id: data.account ? data.account.id : '',
      amount: data.is_income ? Math.abs(data.amount) : (-1) * Math.abs(data.amount),
      time: moment(data.time).format('YYYY-MM-DD'),
      note: data.note,
      flag_excluded_from_calculation: data.flag_excluded_from_calculation,
      is_income: data.is_income
    };
    const { status } : AxiosResponse = yield call(TransactionApiServices.updateTransactionInfo, id, params);

    if (status >= 200 && status < 300) {
      yield put(setLoading(false));
      const isCategoryClicking = getQueryVariable('category_click') || '';
      // if a category clicking -> not route back
      // need remove transaction incase user change category of transaction in History by Category
      // and historyByCategory is not ALL category
      if (isCategoryClicking === 'true' && removeItemInTransactions) {
        yield put(removeItemInTransactionList(data));
      } else if (isCategoryClicking === 'true') {
        yield put(updateTransactionList(data));
      }
      yield put(updateEditingStatus(false));
      Router.push(getAndRemovePrevRoute());
    }
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * deleteTransactionSaga(action: any) {
  try {
    yield put(setLoading(true));
    const { id } = action;
    const { status } : AxiosResponse = yield call(TransactionApiServices.deleteTransactionInfo, id);
    if (status >= 200 && status < 300) {
      yield put(setLoading(false));
      Router.push(getAndRemovePrevRoute());
    }
  } catch (err) {
    errorHandler(err);
  }
}

function * watchGetTransactionInfo() {
  yield takeLatest(actionTypes.GET_TRANSACTION_INFO, getTransactionInfoSaga);
}

function * watchUpdateTransactionInfo() {
  yield takeLatest(actionTypes.UPDATE_TRANSACTION, updateTransactionSaga);
}

function * watchDeleteTransaction() {
  yield takeLatest(actionTypes.DELETE_TRANSACTION, deleteTransactionSaga);
}

export function * transactionSaga() {
  yield all([
    watchGetTransactionInfo(),
    watchUpdateTransactionInfo(),
    watchDeleteTransaction()
  ]);
}
