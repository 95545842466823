import { IAppState } from '@src/interfaces/IAppState';

export const currentIndexLifePlanQuestionSelector = (state: IAppState) => {
  return state.lifeplanState.currentIndexQuestion;
};

export const listLifePlanQuestionsSelector = (state: IAppState) => {
  return state.lifeplanState.listQuestions;
};

export const listAnsweredLifePlanQuestionsSelector = (state: IAppState) => {
  return state.lifeplanState.listAnsweredQuestions;
};

export const isPendingLoadLifePlanQuestionsSelector = (state: IAppState) => {
  return state.lifeplanState.isPendingLoadListQuestions;
};

export const lifePlanResultDataSelector = (state: IAppState) => {
  return state.lifeplanState.lifePlanResultData;
};
