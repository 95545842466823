import Router from 'next/router';
import { EventName } from './appContanst';
import { initialBalanceAcc, initialPointInfo } from './initialData';

if (typeof window !== 'undefined') {
  window.tabAppBack = function(url) {
    Router.push(url);
  };

  window.tabAppReload = function() {
    const event = new Event(EventName.TAB_APP_RELOAD);

    window.dispatchEvent(event);
  };
  window.setUserCondition = function(data) {
    const reponse = generateInitialAccount(data);
    const event = new CustomEvent('GET_INITIAL_DATA_ASSET_TOP', {
      detail: {
        data: reponse
      }
    });
    window.dispatchEvent(event);
  };
}

function createAuJbank(balance, status, isError = false) {
  const date = new Date();
  return {
    ...initialBalanceAcc,
    id: isError ? '' : '1',
    data_source: 4,
    balance: +balance,
    status: isError ? 100 : +status,
    create_date: date.toISOString(),
    service_format: {
      alias: '',
      color: '#EB5505',
      id: '',
      kind: ''
    },
    service_logo: '/static/media/au_jbank.png',
    name: 'auじぶん銀行',
    isError: isError
  };
}

function createAuPay(auPayBalance, isError = false) {
  const date = new Date();
  return {
    ...initialBalanceAcc,
    id: isError ? '' : '2',
    data_source: 2,
    balance: isError ? 0 : +auPayBalance,
    status: isError ? 100 : 70,
    create_date: date.toISOString(),
    service_format: {
      alias: '',
      color: '#EB5505',
      id: '',
      kind: ''
    },
    service_logo: '/static/media/au_jbank.png',
    name: 'au PAY',
    isError: isError
  };
}

function createAuPoint(possessivePoints, pontaLink, isError) {
  const date = new Date();
  return {
    ...initialBalanceAcc,
    id: isError ? '' : '3',
    data_source: 10,
    balance: possessivePoints,
    point_info: { ...initialPointInfo, pontaLink: isError ? '0' : pontaLink },
    create_date: date.toISOString(),
    service_format: {
      alias: '',
      color: '#EB5505',
      id: '',
      kind: ''
    },
    name: 'Ponta ポイント',
    service_logo: '/static/media/au_ponta.png',
    status: isError ? 100 : 0
  };
}


function generateInitialAccount(object) {
  const data = {
    point: { accounts: [] },
    bank: { accounts: [] },
    e_money: { accounts: [] },
    maintenance_status: 0,
    financialBundlePlanMember: object.financialBundlePlanMember,
    payCardHoldSts: object.payCardHoldSts,
    jibunBankAccountHoldSts: object.jibunBankAccountHoldSts,
    financeDisPaysetSts: object.financeDisPaysetSts
  };

  const { jibunBankBalance, jibunBankStatus, auPayBalance, possessivePoints, pontaLink } = object;
  const isAuJbankError = !(jibunBankBalance);
  data.bank.accounts.push(createAuJbank(jibunBankBalance, jibunBankStatus, isAuJbankError));
  const isAuPayError = !auPayBalance;
  data.e_money.accounts.push(createAuPay(auPayBalance, isAuPayError));
  const isAuPointError = !(possessivePoints);
  data.point.accounts.push(createAuPoint(possessivePoints, pontaLink, isAuPointError));
  return data;
}
