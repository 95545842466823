import { actionTypes } from '@src/actions/loginLinkActions';
import { ILoginLinkState } from '@src/interfaces/ILoginState';

const initialState: ILoginLinkState = {
  redirectInfo: {
    serviceId: 0,
    redirectURI: ''
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loginLinkReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_STOCK_SERVICE_INFO: {
      return {
        ...state,
        redirectInfo: {
          serviceId: action.serviceId,
          redirectURI: action.redirectURI
        }
      };
    }

    default:
      return state;
  }
}
