import { appAxios } from './AppAxios';
import { AxiosResponse } from 'axios';

export const UserApiServices = {
  getUserProfile: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/user/info');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUserInfo: async(params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/user/info', params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getUserAssetInfo: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/info');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error.response;
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postDataAnlytic: async(params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/user/analytics', params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }

};
