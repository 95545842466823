import { IAppState } from '@src/interfaces/IAppState';

export const historyListSelector = (appState: IAppState) => (
  appState.historyState
);

export const totalSummaryCategorySelector = (appState: IAppState) => (
  appState.historyState.totalCategory
);

export const loadParamSelector = (appState: IAppState) => (
  appState.historyState.params
);

export const currentAccountInParamSelector = (appState: IAppState) => {
  const params = appState.historyState.params;
  if (params && params.account.id !== 'all') {
    return params.account.name;
  }
  return '全て';
};
