import {
  actionTypes
} from '@src/actions/accountActions';
import { IAccountState } from '@src/interfaces/IAccountState';
import { MAINTENANCE_STATUS } from '@src/utils/appContanst';

const initialAccountInfo = {
  id: '',
  service_id: '',
  service_logo: '',
  service_name: '',
  name: '',
  assets: [],
  is_hide: false,
  data_source: 0,
  balance: '',
  service_format: {
    color: '',
    alias: ''
  },
  is_processing: false,
  error_message: '',
  status: 0,
  next_aggregate_at: '',
  maintenance_status: 0,
  aggregation_error_id: 0
};
const initialState: IAccountState = {
  user: [],
  isPending: true,
  au: [],
  stock: [],
  list: [],
  accountInfo: initialAccountInfo,
  maintenance_status: MAINTENANCE_STATUS.NO_MAINTENANCE
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function accountReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOAD_ACCOUNT_SUCCESS: {
      const defaultAcc = { accounts: [] };
      const data = action.account;
      const { others = defaultAcc } = data;

      return {
        ...state,
        au: data.au.accounts,
        stock: data.stock.accounts,
        others: others.accounts || [],
        maintenance_status: data.maintenance_status,
        info: data.info,
        isPending: false
      };
    }
    case actionTypes.LOAD_ACCOUNT_PENDING: {
      return {
        ...state,
        isPending: true
      };
    }
    case actionTypes.LOAD_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        accountInfo: {
          ...action.account,
          assets: action.account.assets || []
        },
        maintenance_status: action.account.maintenance_status
      };
    case actionTypes.LOAD_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        list: action.account
      };
    case actionTypes.RESET_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: { ...initialAccountInfo }
      };
    }
    default:
      return state;
  }
}
