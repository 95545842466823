export const daysOfMonth = [
  { text: '1日', val: 1 },
  { text: '2日', val: 2 },
  { text: '3日', val: 3 },
  { text: '4日', val: 4 },
  { text: '5日', val: 5 },
  { text: '6日', val: 6 },
  { text: '7日', val: 7 },
  { text: '8日', val: 8 },
  { text: '9日', val: 9 },
  { text: '10日', val: 10 },
  { text: '11日', val: 11 },
  { text: '12日', val: 12 },
  { text: '13日', val: 13 },
  { text: '14日', val: 14 },
  { text: '15日', val: 15 },
  { text: '16日', val: 16 },
  { text: '17日', val: 17 },
  { text: '18日', val: 18 },
  { text: '19日', val: 19 },
  { text: '20日', val: 20 },
  { text: '21日', val: 21 },
  { text: '22日', val: 22 },
  { text: '23日', val: 23 },
  { text: '24日', val: 24 },
  { text: '25日', val: 25 },
  { text: '26日', val: 26 },
  { text: '27日', val: 27 },
  { text: '28日', val: 28 },
  { text: '29日', val: 29 },
  { text: '30日', val: 30 },
  { text: '31日', val: 31 }
];

export const daysOfWeek = [
  {
    text: '月曜日',
    val: 1
  },
  {
    text: '火曜日',
    val: 2
  },
  {
    text: '水曜日',
    val: 3
  },
  {
    text: '木曜日',
    val: 4
  },
  {
    text: '金曜日',
    val: 5
  },
  {
    text: '土曜日',
    val: 6
  },
  {
    text: '日曜日',
    val: 0
  }
];
