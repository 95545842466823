import { appAxios } from './AppAxios';
import { AxiosResponse } from 'axios';

export const TransactionApiServices = {
  getTransactionInfo: async(transaction_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/asset/transactions/${transaction_id}`);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateTransactionInfo: async(transaction_id: string, params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`/asset/transactions/${transaction_id}/edit`, params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  deleteTransactionInfo: async(transaction_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`/asset/transactions/${transaction_id}/delete`);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
