import {
  UPDATE_CURRENT_TUTORIAL_INDEX
} from '@src/actions/tutorialActions';
import { ITutorialState } from '@src/interfaces/ITutorialState';

const initialState: ITutorialState = {
  currentIndex: -1
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tutorialReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_CURRENT_TUTORIAL_INDEX:
      return {
        ...state,
        currentIndex: action.currentIndex
      };
    default:
      return state;
  }
}
