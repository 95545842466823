import { ITransactionItem } from '@src/interfaces/ITransactionState';

export const actionTypes = {
  GET_TRANSACTION_INFO: 'GET_TRANSACTION_INFO',
  GET_TRANSACTION_INFO_SUCCESS: 'GET_TRANSACTION_INFO_SUCCESS',
  UPDATE_TRANSACTION_INFO: 'UPDATE_TRANSACTION_INFO',
  UPDATE_TRANSACTION: 'UPDATE_TRANSACTION',
  DELETE_TRANSACTION: 'DELETE_TRANSACTION'
};

export function getTransactionInfo(id: string) {
  return {
    type: actionTypes.GET_TRANSACTION_INFO,
    id
  };
}

export function getTransactionInfoSuccess(data: ITransactionItem) {
  return {
    type: actionTypes.GET_TRANSACTION_INFO_SUCCESS,
    data
  };
}

export function updateTransactionInfo(data: ITransactionItem) {
  return {
    type: actionTypes.UPDATE_TRANSACTION_INFO,
    data
  };
}

export function updateTransaction(data: ITransactionItem, removeItemInTransactions?: boolean) {
  return {
    type: actionTypes.UPDATE_TRANSACTION,
    data,
    removeItemInTransactions
  };
}

export function deleteTransaction(id: string) {
  return {
    type: actionTypes.DELETE_TRANSACTION,
    id
  };
}
