export const actionTypes = {
  GET_LOGIN_LINK: 'GET_LOGIN_LINK',
  UPDATE_STOCK_SERVICE_INFO: 'UPDATE_STOCK_SERVICE_INFO',
  LINK_ACCOUNT_SUCCESS: 'LINK_ACCOUNT_SUCCESS'
};

export function getServiceLoginLink(id: string | number, redirectURI?: string) {
  return {
    type: actionTypes.GET_LOGIN_LINK,
    id,
    redirectURI
  };
}

export function updateStockRedirectInfo(serviceId: string | number, redirectURI: string) {
  return {
    type: actionTypes.UPDATE_STOCK_SERVICE_INFO,
    serviceId,
    redirectURI
  };
}

export function loginStockSuccess(params: any) {
  return {
    type: actionTypes.LINK_ACCOUNT_SUCCESS,
    params
  };
}
