import { appAxios } from './AppAxios';
import { AxiosResponse } from 'axios';

export const BannerApiService = {
  getBannerByscreen: async(nameScreen: string, userType?: string) => {
    try {
      const params = {
        screen: nameScreen,
        user_types: userType || ''
      };

      const { data }: AxiosResponse = await appAxios.get('/asset/banners', {
        params: params
      });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  }
};
